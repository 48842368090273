import { lazy, Suspense } from "react";
import { redirect } from "react-router-dom";
import Login from "@PAGE/login/Login";
import App from "../App";
import ErrorBoundary from "@PAGE/NotFound/ErrorBoundary";

const Home = lazy(() => import("@PAGE/home/Home"));
const Project = lazy(() => import("@PAGE/project/Project"));
const Add = lazy(() => import("@PAGE/project/Add"));
const Boundary = lazy(() => import("@PAGE/project/Boundary"));
const Source = lazy(() => import("@PAGE/project/Source"));
const Calculate = lazy(() => import("@PAGE/project/Calculate"));
const Risk = lazy(() => import("@PAGE/project/Risk"));
const Enterprise = lazy(() => import("@PAGE/user/Enterprise"));
const Register = lazy(() => import("@PAGE/register/Register"));
const Recovery = lazy(() => import("@PAGE/recovery/Recovery"));
const Account = lazy(() => import("@PAGE/user/Account"));
const FunctionList = lazy(() => import("@PAGE/user/FunctionList"));
const NotFound = lazy(() => import("@PAGE/NotFound/NotFound"));
const CalculateTable = lazy(() =>
  import("@COM/project/calculate/CalculateTable")
);
const Analyse = lazy(() => import("@PAGE/project/Analyse"));
const Sbti = lazy(() => import("@PAGE/project/Sbti"));
const SbtiChart = lazy(() => import("@PAGE/project/Sbti/SbtiChart"));
const SbtiEquipmentCreate = lazy(() => import("@PAGE/project/Sbti/SbtiEquipmentCreate"));
const Verification = lazy(() => import("@PAGE/project/Verification"));
const Compare = lazy(() => import("@PAGE/compare/Compare"));
const GHGReport = lazy(() => import("@PAGE/project/GHGReport"));

const SampleDownload = lazy(() => import("@COM/SampleDownload"));

const BoundaryTable = lazy(() =>
  import("@PAGE/project/boundary/BoundaryTable")
);
const SbtiSetting = lazy(() => import("@PAGE/project/boundary/SbtiSetting"));
const ProjectManagement = lazy(() =>
  import("@PAGE/project/boundary/ProjectManagement")
);
const CarbonCredit = lazy(() => import("@PAGE/project/CarbonCredit"));

const NoticeBoard = lazy(() => import("@PAGE/noticeboard/NoticeBoard"));

const AppRoutes = [
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/recovery",
    element: <Recovery />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: ":key", element: <Recovery />, errorElement: <ErrorBoundary /> },
    ],
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    loader: () => {
      let data = sessionStorage.getItem("netZero");
      if (!data) {
        return redirect("/login");
      }
      return null;
    },
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "project",
        element: <Project />,
        errorElement: <ErrorBoundary />,
        children: [
          { path: "add", element: <Add />, errorElement: <ErrorBoundary /> },
          {
            path: "boundary",
            element: <Boundary />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: <BoundaryTable />,
              },
              {
                path: "sbtisetting",
                element: <SbtiSetting />,
              },
              {
                path: "project-management",
                element: <ProjectManagement />,
              },
            ],
          },
          {
            path: "source",
            element: <Source />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "calculate",
            element: <Calculate />,
            children: [
              {
                path: ":sourceLink",
                element: <CalculateTable />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "risk",
            element: <Risk />,
            children: [
              {
                path: ":id",
                element: <Risk />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "analyse",
            element: <Analyse />,
          },
          {
            path: "sbtichart",
            element: <Sbti />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: <SbtiChart />,
              },
              {
                path: "sbtiequipment",
                element: <SbtiEquipmentCreate />,
              },
            ]
          },

          {
            path: "carboncredit",
            element: <CarbonCredit />,
          },
          {
            path: "verification",
            element: <Verification />,
          },
          {
            path: "compare",
            element: <Compare />,
          },
          {
            path: "ghgreport",
            element: <GHGReport />,
          },
        ],
      },
      {
        path: "user",
        element: <Project />,
        errorElement: <ErrorBoundary />,
        children: [
          { path: "enterprise", element: <Enterprise /> },
          { path: "account", element: <Account /> },
          { path: "functionList", element: <FunctionList /> },
        ],
      },
      {
        path: "advisercompare",
        element: <Compare type="adviser" />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "excel_sample/key/mjm",
        element: <SampleDownload />,
      },
      {
        path: "noticeboard",
        element: <NoticeBoard />,
        errorElement: <ErrorBoundary />,
        children:[
          {
            path: ":category",  
            element: <NoticeBoard />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: ":category/:id",  
            element: <NoticeBoard />,
            errorElement: <ErrorBoundary />,
          },
        ]
      },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
];

export default AppRoutes;
