import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  // 取得總量
  GET_STAGETOTAL_API,
} from "@CON";
import { store } from "@STORE";
import { formatTotalScalar, formatScalar } from "./formatNumber";

const getStageTotal = async (LCAID) => {
  const { result, data } = await axios.get(`${GET_STAGETOTAL_API}/${LCAID}`);

  if (result === "success") {
    return data;
  } else {
    return [];
  }
};

const calculateLCAPeriod = () => {
  const { StartDate, EndDate } = store.getState().project.LCAInfo;
  const LCAPeriod =
    new Date(EndDate).getMonth() - new Date(StartDate).getMonth() + 1;
  return LCAPeriod || 1;
};

const getTotalKgCO2e = async ({
  data,
  stageOptions,
  LCAID,
  setFn,
  viewType = "device",
  param,
  calculateType, // 目前類別 如 綠電 一般用電
  typeTitleForTableOption = [],
  otherApiInformation,
  tDigit = 3,
}) => {
  let totalList = [];
  // 分為一般類別及生命週期類別
  if (otherApiInformation && otherApiInformation.GHGinventoryItemNo) {
    const totalObj = await getStageTotal(LCAID);
    for (let stage in stageOptions) {
      totalList.push({
        type: stageOptions[stage],
        value: formatTotalScalar(totalObj[`s${stage}`], tDigit),
      });
    }
  } else {
    if (viewType === "month") {
      if (param === "WorkHour") {
        typeTitleForTableOption.forEach(({ label, value }) => {
          const list = data.filter(({ TypeID }) => TypeID === value);

          totalList.push({
            type: label,
            key: uuidv4(),
            value: formatScalar(
              list.reduce((acc, { KgCO2e }) => acc + parseFloat(KgCO2e), 0)
            ),
            scalar: formatScalar(
              list.reduce(
                (acc, { TotalHours = 0 }) => acc + parseFloat(TotalHours),
                0
              )
            ),
          });
        });
      } else {
        typeTitleForTableOption.forEach(({ label, value }) => {
          const list = data.filter(
            ({ ElecNumber, Type, SteamNo, PlusMinus }) =>
              (ElecNumber === value && Type === calculateType) ||
              (SteamNo === value && PlusMinus === calculateType)
          );

          totalList.push({
            type: label,
            key: uuidv4(),
            value: formatScalar(
              list.reduce((acc, { KgCO2e }) => acc + parseFloat(KgCO2e), 0)
            ),
            scalar: formatScalar(
              list.reduce((acc, { Scalar = 0 }) => acc + parseFloat(Scalar), 0)
            ),
          });
        });
      }
    } else {
      let types = [];
      let LCAPeriod = 1;
      if (param === "RefrigerantParameterID2" || param === "Refrigerant") {
        LCAPeriod = calculateLCAPeriod();
      }
      if (param === "RefrigerantParameterID2") {
        // 台數 *原始充填量 * 逸散率 * 使用月數 / 盤查週期
        types = new Set(data.map(({ ParameterID2Title }) => ParameterID2Title));
        types.forEach((type) => {
          const list = data.filter(({ ParameterID2Title }) =>
            ParameterID2Title?.includes(type)
          );
          totalList.push({
            type,
            key: uuidv4(),
            value: formatScalar(
              list.reduce((acc, { KgCO2e }) => acc + parseFloat(KgCO2e), 0)
            ),
            scalar: formatScalar(
              list.reduce(
                (acc, { Scalar = 0, TotalNumber = 1, UsedMonth = 0, factor }) =>
                  acc +
                  parseFloat(
                    (Scalar * TotalNumber * factor * UsedMonth) / LCAPeriod
                  ),
                0
              )
            ),
          });
        });
      } else if (param === "OtherCompounds") {
        types = new Set(
          data.map(({ ParameterIDTitle, ingredientName }) =>
            ParameterIDTitle?.includes("/")
              ? ParameterIDTitle.split(",")[1]
              : ParameterIDTitle
              ? ParameterIDTitle
              : ingredientName
          )
        );
        types.forEach((type) => {
          const list = data.filter(
            ({ ParameterIDTitle, ingredientName }) =>
              (type && ingredientName?.includes(type)) ||
              (type && ParameterIDTitle?.includes(type))
          );
          totalList.push({
            type,
            key: uuidv4(),
            value: formatScalar(
              list.reduce((acc, { KgCO2e }) => acc + KgCO2e, 0)
            ),
            scalar: formatScalar(
              list.reduce((acc, { Scalar = 0 }) => acc + Scalar, 0)
            ),
          });
        });
      } else {
        types = new Set(
          data.map(
            ({ fuelType, ParameterIDTitle }) => fuelType || ParameterIDTitle
          )
        );
        types.forEach((type) => {
          const list = data.filter(
            ({ fuelType, ParameterIDTitle }) =>
              fuelType === type || ParameterIDTitle === type
          );
          if (param === "Refrigerant") {
            totalList.push({
              type,
              key: uuidv4(),
              value: formatScalar(
                list.reduce((acc, { KgCO2e }) => acc + parseFloat(KgCO2e), 0)
              ),
              scalar: formatScalar(
                list.reduce(
                  (
                    acc,
                    { Scalar = 0, TotalNumber = 1, factor, UsedMonth = 1 }
                  ) =>
                    acc +
                    parseFloat(
                      (Scalar * TotalNumber * factor * UsedMonth) / LCAPeriod
                    ),
                  0
                )
              ),
            });
          } else {
            totalList.push({
              type,
              key: uuidv4(),
              value: formatScalar(
                list.reduce((acc, { KgCO2e }) => acc + parseFloat(KgCO2e), 0)
              ),
              scalar: formatScalar(
                list.reduce(
                  (acc, { Scalar = 0 }) => acc + parseFloat(Scalar),
                  0
                )
              ),
            });
          }
        });
      }
    }
  }
  setFn(totalList);
};

export { getTotalKgCO2e };
