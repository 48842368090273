function importDataSelect({
  selected,
  selectedRows,
  oldData,
  newData,
  record: { key },
  importData,
  setImportData,
  data,
  setSelectedRowKeys,
  selectedRowKeys,
}) {
  // setSelectedRowKeys(selectedRows.map((item) => item.key));
  // 如果沒有 selected 則把對應的 newValue 資料修正為舊的
  if (!selected) {
    const oldItem =
      oldData?.find(({ ID }) => ID === parseInt(key.split("_")[0])) || {};

    // 將該筆資料從 newValue 中移除，並添加一個新的 object 裡面是放舊值 importData 是放置新值的地方
    const coverList = importData.filter(
      ({ ID }) => ID !== parseInt(key.split("_")[0])
    );
    data && setImportData([...coverList, oldItem]);

    setSelectedRowKeys(
      selectedRows
        .filter((item) => item.key.split("_")[0] !== key.split("_")[0])
        .map((item) => item.key)
    );
  } else {
    let newSelectedArr = [];
    data.forEach(({ children }) => {
      const newSelected =
        children
          .find((item) => item.key.split("_")[0] === key.split("_")[0])
          ?.children.map((item) => item.key) || [];
      newSelectedArr = [...newSelectedArr, ...newSelected];
    });
    newSelectedArr.length === 0
      ? setSelectedRowKeys([...selectedRowKeys, ...newSelectedArr, key])
      : setSelectedRowKeys([...selectedRowKeys, ...newSelectedArr]);

    const newItem =
      newData?.find(({ ID }) => ID === parseInt(key.split("_")[0])) || {};
    const coverList = importData.filter(
      ({ ID }) => ID !== parseInt(key.split("_")[0])
    ); // 原本相同的 key 的值會是放舊資料
    data && setImportData([...coverList, newItem]);
  }
}

function importDataSelectAll({ selected, selectedRows, setSelectedRowKeys }) {
  if (!selected) {
    setSelectedRowKeys(selectedRows);
  } else {
    setSelectedRowKeys(selectedRows.map(({ key }) => key));
  }
}

export { importDataSelect, importDataSelectAll };
