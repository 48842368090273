// 蒸氣號 api 轉換為 plus / minus
const transferSteamTypeName = (param, calculateType) => {
  let steamType = "";
  if (param === "SteamUsages") {
    switch (calculateType) {
      case "+":
        steamType = "plus";
        break;
      case "-":
        steamType = "minus";
        break;
      default:
        break;
    }
  }
  return steamType;
};

export { transferSteamTypeName };
