import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sbtiPackageSlice = createSlice({
  name: "sbtiPackage",
  initialState: {
    SBTiRequest: {},
    SBTiBaseYearLCA: {},
  },
  reducers: {
    getSBTi(state, { payload }) {
      state.SBTiRequest = payload;
    },
    setBaseYearLCA(state, { payload }) {
      state.SBTiBaseYearLCA = payload;
    },
  },
});

const { reducer, actions } = sbtiPackageSlice;
export const { getSBTi, setBaseYearLCA } = actions;
export default reducer;