import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin, Slider } from 'antd';
import axios from 'axios';
import { setSiteUser } from "@STORE";

const CaptchaVerification = ({ setCaptchaX, setGuid, refreshCaptcha, apiUse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [captchaUse, setCaptchaUse] = useState(false);
  const [verificationToken, setVerificationToken] = useState(null);
  const captchaPuzzleRef = useRef(null);
  const [sliderValue, setSliderValue] = useState(0); // 添加一個狀態來追蹤滑塊值

  const getCaptchaVerificationCode = async () => {
    const res = await axios.get(apiUse);
    setVerificationToken(res.data?.CaptchaItem2);
    setGuid(res.data?.CaptchaItem2.guid);
    setCaptchaUse(res.data?.CaptchaUse);
    dispatch(setSiteUser(res.data?.SiteUser));
  };

  useEffect(()=>{
    setVerificationToken(refreshCaptcha);
    setGuid(refreshCaptcha?.guid);
    setSliderValue(0);
    if (captchaPuzzleRef.current) {
      captchaPuzzleRef.current.style.transform = 'translateX(0px)';
    }
  },[refreshCaptcha]);

  useEffect(()=>{
    getCaptchaVerificationCode();
  },[]);

  if (!captchaUse) return null;

  return ( 
    <div className="w-100 text-center mb-5 d-flex justify-content-center align-items-center">
      {verificationToken !== null ? (
        <div
          className="position-relative"
          style={{
            width: "280px",
            height: "155px",
          }}
        >
          <img
            className="mb-2"
            src={verificationToken?.background}
            alt={t("User.verificationCode")}
            style={{
              width: "280px",
              height: "155px",
            }}
          />
          <img
            src={verificationToken?.slider}
            alt={t("User.CAPTCHA_Puzzle")}
            className="position-absolute start-0 top-0"
            ref={captchaPuzzleRef}
            style={{
              width: "62px",
              height: "155px",
            }}
          />
          <Slider
            value={sliderValue}
            min={0}
            max={280 - (captchaPuzzleRef?.current?.width || 0)}
            className="captcha-slider"
            tooltip={{ formatter: null }}
            onChange={(value) => {
              captchaPuzzleRef.current.style.transform = `translateX(${value}px)`;
              setSliderValue(value); // 更新滑塊的值
              setCaptchaX(value);
            }}
          />
        </div>
      ) : (
        <Spin tip={t("User.Waiting")} />
      )}
    </div>
  );
}

export default CaptchaVerification;