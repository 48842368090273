import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { LOGIN_API } from "@CON";
import { messageGenerator } from "@UTIL";
import { setUser, setUserType, setToken } from "@STORE";
import LoginRuleModal from "./LoginRuleModal";
import CaptchaVerification from '@COM/CaptchaVerification';
import "./LoginForm.scss";

export default function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [btnLoading, updateBtnLoading] = useState(false);

  // TODO: 串接 api (等 api 規格確認)
  const [form] = Form.useForm();
  const { userInfo } = useSelector((state) => state.user);
  const [captchaX, setCaptchaX] = useState(0);
  const [guid, setGuid] = useState(null);
  const [refreshCaptcha, setRefreshCaptcha] = useState(null);

  const onFinish = async (values) => {
    updateBtnLoading(true);
    const { username, password } = values;
    const res = await axios.post(LOGIN_API, {
      CaptchaUse: true,
      UserName: username,
      Password: password,
      RememberMe: false,
      captchaX,
      guid,
    });
    const { result = "error", data, errMsg } = res;
    if (result === "success") {
      dispatch(setUser(data.User)); // 更新用戶狀態
      dispatch(setUserType(data.UserType)); // 更新用戶狀態
      dispatch(setToken(data.token)); // 更新用戶 token
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      const sameUser = userInfo?.Id === data.User.Id;
      if (redirectUrl && sameUser) {
        navigate(redirectUrl, { replace: true });
      } else {
        console.log("Login")
        sessionStorage.setItem("netZero", "true");
        navigate("/", { replace: true });
      }
    } else {
      messageGenerator({ result, content: errMsg ?? t("MessageText.Login failed") });
      setRefreshCaptcha(data); // 切換狀態更新驗證碼圖片
    }
    updateBtnLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  }; 
  return (
    <Form
      name="login"
      form={form}
      className="login-form w-100"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

      <Form.Item
        label={t("User.Username")}
        name="username"
        rules={[
          {
            required: true,
            message: t("FormMsg.Required"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("User.Password")}
        name="password"
        rules={[
          {
            required: true,
            message: t("FormMsg.Required"),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <CaptchaVerification setCaptchaX={setCaptchaX} setGuid={setGuid} refreshCaptcha={refreshCaptcha} apiUse={LOGIN_API}/>
      <LoginRuleModal />
      <div className="w-100 d-flex gap-5 justify-content-between">
        <Button
          loading={btnLoading}
          type="primary"
          htmlType="submit"
          className="d-block w-100"
        >
          {t("User.Login")}
        </Button>
        <Button
          type="primary"
          className="d-block w-100"
          style={{ backgroundColor: '#34c38f' }}
          onClick={() => navigate("/register")}
        >
          {process.env.REACT_APP_ENV !== "production"
            ? t("Register.Apply")
            : t("Register.Register")}
        </Button>
      </div>
    </Form>
  );
}
