import { createSlice } from "@reduxjs/toolkit";

const sourceSlice = createSlice({
  name: "user",
  initialState: {
    dataSource: [], // 主要用在新增設備類型的排放源
    addModalOpen: false,
    uploadData: "",
    sourceDeviceID: "",
    sourceDevice: {},
    SystemMenus: [],
  },
  reducers: {
    setDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setAddModalOpen: (state, { payload }) => {
      state.addModalOpen = payload;
    },
    setUploadData: (state, { payload }) => {
      state.uploadData = payload;
    },
    setSourceDeviceID: (state, { payload }) => {
      state.sourceDeviceID = payload;
      state.sourceDevice =
        state.dataSource.find((item) => item.ID === payload) || {};
    },
    setSystemMenus: (state, { payload }) => {
      state.SystemMenus = payload;
    },
  },
});

const { reducer, actions } = sourceSlice;
export const { setDataSource, setAddModalOpen, setUploadData, setSourceDeviceID, setSystemMenus} = actions;

export default reducer;
