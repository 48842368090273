import { useState } from "react";
import { Button, Modal, Checkbox, Form, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Privacy from "./Privacy";
import UserRule from "./UserRule";
import "./LoginRuleModal.scss";

export default function LoginRuleModal() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");

  const openModal = (type) => {
    setType(type);
    setOpen(true);
  };

  return (
    <>
      <Form.Item
        name="rule"
        className="d-flex"
        rules={[
          {
            required: true,
            message: t("FormMsg.Required"),
          },
          {
            validator: (_, value) => {
              if (value && value.length === 1) {
                return Promise.resolve(value);
              }
              return Promise.reject(t("User.PolicyRequired"));
            },
          },
        ]}
      >
        <Checkbox.Group>
          <Row className="policy">
            <Col className="me-3">
              <Checkbox value="user">
                <span className="policyHover" onClick={() => openModal("user")}>
                  {t("User.Use")}
                </span>
                <span> & </span>
                <span
                  className="policyHover"
                  onClick={() => openModal("privacy")}
                >
                  {t("User.Policy")}
                </span>
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Modal
        title={
          type === "user" ? (
            <h5 className="fw-bold">{t("User.Use")}</h5>
          ) : (
            <h5 className="fw-bold">{t("User.Policy")}</h5>
          )
        }
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ background: "#556EE6" }}
            onClick={() => setOpen(false)}
          >
            {t("Buttons.Confirm")}
          </Button>,
        ]}
        width={1000}
      >
        {type === "user" ? <UserRule /> : <Privacy />}
      </Modal>
    </>
  );
}
