import { theme } from "antd";

export const customerThemeToken = {
  token: {
    blue: "#F0F9FF",
    //primary
    colorPrimary: "#556ee6",
    // error
    colorError: "#f46a6a",
    //success
    colorSuccess: "#34c38f",
    colorInfo: "#50a5f1",
    heightLayoutHeader: 80,
    wireframe: true,
    fontSize: 18,
    borderRadius: 4,
    size: 16,
  },
};
