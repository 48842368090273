import axios from "axios";
// import { saveAs } from "file-saver";
import messageGenerator from "./messageGenerator";
import { t } from "i18next";

// function b64toFile(b64Data, filename, contentType) {
//   let sliceSize = 512;
//   let byteCharacters = window.atob(b64Data);
//   let byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     let slice = byteCharacters.slice(offset, offset + sliceSize);
//     let byteNumbers = new Array(slice.length);

//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }
//     let byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   let file = new File(byteArrays, filename, { type: contentType });
//   return file;
// }

const exportSource = async ({
  apiPath,
  LCAID,
  Type = "",
}) => {
  const { Status, Data } = await axios.post(apiPath, {
    LCAID,
    Type,
    GHGinventoryItemNo: Type,
  });
  if (Status && Data.data) {
    // base64Data 是服务器获取到的数据
    // let file = b64toFile(
    //   Data.data,
    //   Data.FileName,
    //   "application/vnd.ms-excel;charset=utf-8"
    // );

    // // 利用FileSaver.js 下载文件为Excel文件
    // saveAs(file, Data.FileName);

    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Data.data}`;
    const link = document.createElement("a");
    link.href = linkSource;
    link.download = Data.FileName;
    document.body.appendChild(link);
    link.click();
    messageGenerator({
      result: "success",
      content: t("MessageText.Download successful"),
    });
  } else {
    messageGenerator({ content: t("MessageText.Download failed") });
  }
};

export { exportSource };
