const formatScalar = (scalar) => {
  return parseFloat(scalar).toFixed(4);
};

const formatTotalScalar = (scalar, digit) => {
  return parseFloat(scalar).toFixed(digit);
};

const formatKgCO2e = (KgCO2e) => {
  const value = Math.round(parseFloat(KgCO2e) * 1000000000);
  const formatValue = (value / 1000000000).toString().split(".");
  if (KgCO2e && formatValue.length > 1) {
    const times = 10 - formatValue[1]?.length;
    if (formatValue[1].length !== 10) {
      for (let i = 0; i < times; i++) {
        formatValue[1] += "0";
      }
    }
    return formatValue.join(".");
  } else {
    return parseFloat(KgCO2e).toFixed(10);
  }
  // return Number(parseFloat(KgCO2e).toFixed(10));
};

const formatNumber = (num, digit = 4) => parseFloat(num).toFixed(digit);

export { formatScalar, formatTotalScalar, formatKgCO2e, formatNumber };
