import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CHANGELANG_API } from "@CON";

export const fetchLangByKey = createAsyncThunk(
  "pageInfo/fetchLangByKey",
  async (lang, { dispatch }) => {
    await axios.post(`${CHANGELANG_API}/${lang}`);
    return lang;
  }
);

const pageInfoSlice = createSlice({
  name: "pageInfo",
  initialState: {
    antdLanguage: "zh-tw",
    breadcrumbItem: [
      {
        title: "首頁",
        key: "/",
      },
    ],
    barMenu: "app",
  },
  reducers: {
    setAntdLangage(state, { payload }) {
      state.antdLanguage = payload;
    },
    setBarMenu(state, { payload }) {
      state.barMenu = payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchLangByKey.fulfilled, (state, { payload }) => {
      // Add user to the state array
      state.antdLanguage = payload;
    });
  },
});

const { reducer, actions } = pageInfoSlice;
export const { setAntdLangage, setBarMenu } = actions;
export default reducer;
