import { useEffect } from "react";
import { useResolvedPath, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { breadcrumbList } from "@CON";
import { setCompareFabs } from "@STORE";

function BreadCrumb() {
  const { t } = useTranslation();
  const {
    project: { LCAInfo },
    analyse: { compareFabs },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { pathname } = useResolvedPath();
  const breadcrumbItem = pathname
    .split("/")
    .filter((p) => p !== "" && isNaN(p))
    .map((p) => {
      return {
        key: p,
        text: breadcrumbList[p] ? t(`Breadcrumb.${breadcrumbList[p]}`) : "",
      };
    });

  useEffect(() => {
    if (compareFabs.length === 0) {
    } else if (pathname.split("/")[1] !== "analyse") {
      dispatch(
        setCompareFabs({ compareFabs: [], compareType: "", yearList: [] })
      );
    }
  }, [pathname]);

  return (
    <>
      <ol className="breadcrumb my-1">
        <li className="breadcrumb-item" key="/">
          <Link to="/" className="text-muted">{t("Breadcrumb.home")}</Link>
        </li>
        {breadcrumbItem?.map(({ text, key }, index) => (
          <li
            className={`${
              index === breadcrumbItem.length - 1
                ? "breadcrumb-item active"
                : "breadcrumb-item"
            }`}
            key={key}
          >
            {breadcrumbItem.length > 1 &&
            key === "project" &&
            (!breadcrumbItem.some(({ key }) => key === "add") ||
              LCAInfo?.ID) ? (
              <Link to={key}>{LCAInfo?.LCAname}</Link>
            ) : key !== "project" &&
              breadcrumbItem.some(({ key }) => key === "add") &&
              LCAInfo?.ID ? (
              t("Breadcrumb.edit")
            ) : (
              text
            )}
          </li>
        ))}
      </ol>
    </>
  );
}

export default BreadCrumb;
