import { createSlice } from "@reduxjs/toolkit";

// const statusText = [
//   { status: 0, text: "高階分析中", color: "#5d8aa8" },
//   { status: 1, text: "進行中", color: "#12a182" },
//   { status: 2, text: "待審核", color: "#fcc307" },
//   { status: 3, text: "有缺失", color: "#c04851" },
//   { status: 4, text: "已核可", color: "#5d8aa8" },
//   { status: 5, text: "已完成", color: "#483332" },
// ];

const projectSlice = createSlice({
  name: "project",
  initialState: {
    LCAInfo: null,
    LCAStatus: 6, // 無資料
    LCADisabled: false,
  },
  reducers: {
    getLCA: (state, { payload }) => {
      state.LCAInfo = payload;
      state.LCAStatus = payload.Status;
      if (
        payload.Status === 2 ||
        payload.Status === 4 ||
        payload.Status === 5
      ) {
        state.LCADisabled = true;
      }
    },
    clearLCA: (state) => {
      state.LCAInfo = null;
      state.LCAStatus = 6;
      state.LCADisabled = false;
    },
    changeLCAStatusToVerify: (state, { payload }) => {
      state.LCAStatus = payload;
      // 根據 payload 的值設置 LCADisabled
      if (payload == 1) {
        state.LCADisabled = false; // 當 payload 等於 1 時，設置 LCADisabled 為 false
      } else if (payload == 2 || payload == 5) {
        state.LCADisabled = true; // 當 payload 等於 2 或 5 時，設置 LCADisabled 為 true
      }
    },
    
  },
});

const { reducer, actions } = projectSlice;
export const { getLCA, clearLCA, changeLCAStatusToVerify } = actions;

export default reducer;
