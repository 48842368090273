import React from "react";
import { useTranslation } from "react-i18next";

export default function UserRule() {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: "18px" }}>
      <h5 className="fw-bold">{t("UserRule.Title")}</h5>
      {t("UserRule.Welcome")}
      <p>
        {t("UserRule.Clause1")}
      </p>
      <p>
        {t("UserRule.Clause2")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section1.Title")}</h6>
      <p>
        {t("UserRule.Section1.Content1")}
      </p>
      <p>
        {t("UserRule.Section1.Content2")}
      </p>
      <p>
        {t("UserRule.Section1.Content3")}
      </p>
      <p>
        {t("UserRule.Section1.Content4")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section2.Title")}</h6>
      <p>
        {t("UserRule.Section2.Content1")}
      </p>
      <p>
        {t("UserRule.Section2.Content2")}
      </p>
      <p>
        {t("UserRule.Section2.Content3")}
      </p>
      <p>
        {t("UserRule.Section2.Content4")}
      </p>
      <p>
        {t("UserRule.Section2.Content5")}
      </p>
      <p>
        {t("UserRule.Section2.Content6")}
        <br />
        ※ {t("UserRule.Section2.Content7")}
        <br />
        ※ {t("UserRule.Section2.Content8")}
        <br />
        ※ {t("UserRule.Section2.Content9")}
        <br />
        ※ {t("UserRule.Section2.Content10")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section3.Title")}</h6>
      <p>
        {t("UserRule.Section3.Content1")}
      </p>
      <p>
        {t("UserRule.Section3.Content2")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section4.Title")}</h6>
      <p>
        {t("UserRule.Section4.Content1")}
      </p>
      <p>
        {t("UserRule.Section4.Content2")}
      </p>
      <p>
        {t("UserRule.Section4.Content3")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section5.Title")}</h6>
      <p>
        {t("UserRule.Section5.Content1")}
      </p>
      <p>
        {t("UserRule.Section5.Content2")}
      </p>
      <h6 className="fw-bold">{t("UserRule.Section6.Title")}</h6>
      <p>
        {t("UserRule.Section6.Content1")}
      </p>
      <p>
        {t("UserRule.Section6.Content2")}
      </p>
      <p>{t("UserRule.footer")}</p>
    </div>
  );
}
