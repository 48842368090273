import { Button, message, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import axios from "axios";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  downloadReferenceFile,
  showDeleteConfirm,
  messageGenerator,
  formatKgCO2e,
} from "@UTIL";
import {
  // 各類別資料POST
  POSTREFRIGERANT_API,
  POSTFIREEQUIPMENT_API,
  POSTKITCHEN_API,
  POSTVEHICLE_API,
  POSTOTHERCOMPOUND_API,
  POSTLIFECYCLE_API,
  POSTCOMMUTING_API,
  POSTPURCHASEDPRODUCT_API,
  POSTUPSTREAMTRANSPORT_API,
  // DELETE
  DELETEVEHICLE_API,
  DELETEREFRIGERANT_API,
  DELETEFIREEQUIPMENT_API,
  DELETEKITCHEN_API,
  DELETEOTHERCOMPOUND_API,
  DELETELIFECYCLE_API,
  DELETEPURCHASEDPRODUCT_API,
  DELETEUPSTREAMTRANSPORT_API,
  DELETECOMMUTING_API,
} from "./apiList";
import {
  store,
  setUploadData,
  setAddModalOpen,
  setSourceDeviceID,
} from "@STORE";
import { faL } from "@fortawesome/free-solid-svg-icons";

const download = ({ apiPath, ID }) => {
  const {
    source: { dataSource },
  } = store.getState();
  const data = dataSource.find(({ ID: itemId }) => itemId === ID);
  const { ReferenceFileLink, ReferenceServicePhotoUrl } = data;
  (ReferenceFileLink || ReferenceServicePhotoUrl) &&
    downloadReferenceFile([ReferenceFileLink, ReferenceServicePhotoUrl]);
};

const edit = ({ apiPath, ID }) => {
  // console.log(apiPath, ID);
  store.dispatch(setSourceDeviceID(ID));
  store.dispatch(setAddModalOpen(true));
};

const del = async ({ apiPath, ID }) => {
  // 請求開始前顯示 Modal 遮罩層
  const modal = Modal.info({
    title: (
      <div className="d-flex align-items-center gap-2">
        <p className="fw-normal mb-0">{t("MessageText.Deleteing")}</p>
        <LoadingOutlined className="h2 fw-normal" spin />
      </div>
    ),
    footer: null,  // 確保不顯示任何按鈕
    maskClosable: false,  // 不允許點擊遮罩層關閉
  });
  try {
    const {
      source: { dataSource },
      project: { LCAInfo },
    } = store.getState();
  // 判斷是否為其他
    let ARnGWPid = dataSource[0]?.ARnGWPid || "";

    const res = await axios.delete(
      `${apiPath}/${ID}/${ARnGWPid ? `${LCAInfo.ID}/` : ""}`
    );

    if (res?.result === "success") {
      // 刪除後更新store
      store.dispatch(setUploadData(ID));
      messageGenerator({
        result: "success",
        content: t("MessageText.Delete successful"),
      });
    } else {
      messageGenerator({
        content: res.errMsg || t("MessageText.Delete failed"),
      });
    }
  } catch (error) {
    messageGenerator({
      content: t("MessageText.Delete failed"),
    });
  } finally {
    // 關閉 Modal
    modal.destroy();
  }
};

const TableItemTitle = ({ title }) => {
  const { t } = useTranslation();
  return t(title);
};

const tableItemGenerator = (
  {
    title,
    key,
    render = null,
    width = 130,
    ellipsis = false,
    fixed = false,
    readonly = false,
    filters = null,
    onFilter = null,
    isIII = false,
    isNormal = false,
  },
  isEdit
) => ({
  title: <TableItemTitle title={title} />,
  key,
  dataIndex: key,
  className: "calculate_table",
  align: "center",
  width,
  ellipsis,
  fixed,
  readonly,
  filters,
  onFilter,
  isIII,
  isNormal,
  onCell: () => {
    if (ellipsis) {
      return {
        style: {
          maxWidth: "300px",
        },
      };
    }
  },
  render: Array.isArray(render)
    ? (
      _,
      { ID, ReferenceFileLink = null, ReferenceServicePhotoUrl = null }
    ) => {
      const { LCADisabled } = store.getState().project;
      return LCADisabled || !isEdit ? (
        <></>
      ) : (
        render?.map(({ text, key, fn, apiPath }) => (
          <Button
            key={`${ID}_${key}`}
            type="link"
            danger={key === "del"}
            disabled={
              LCADisabled ||
              (key === "download" &&
                !ReferenceFileLink &&
                !ReferenceServicePhotoUrl)
            }
            icon={
              key === "del" ? (
                <DeleteOutlined />
              ) : key === "edit" ? (
                <EditOutlined />
              ) : (
                <DownloadOutlined />
              )
            }
            shape="round"
            className={`me-1 px-1 border-0 ${key === "edit" && !LCADisabled ? "text-success" : ""
              }`}
            size="large"
            onClick={() => {
              if (key === "del") {
                fn(del, { apiPath, ID });
              } else {
                fn({ apiPath, ID });
              }
            }}
          ></Button>
        ))
      );
    }
    : render
      ? render
      : (_) => {
        return <>{_}</>;
      },
});

const MonthTitle = ({ title }) => {
  const { t } = useTranslation();
  return <>{t(`Months.${title}`)}</>;
};

const tableMonthGenerator = ({
  title,
  dataIndex,
  readonly = false,
  width = 200,
  fixed = "",
}) => ({
  title: <MonthTitle title={dataIndex} />,
  width,
  key: dataIndex,
  dataIndex,
  className: "calculate_table",
  readonly,
  align: "center",
  fixed,
});

const MONTH = [
  {
    dataIndex: "Item_title",
    title: "項目",
    readonly: true,
    width: 200,
    className: "calculate-table",
    fixed: "left",
  },
  { dataIndex: "January", title: "1月" },
  { dataIndex: "February", title: "2月" },
  { dataIndex: "March", title: "3月" },
  { dataIndex: "April", title: "4月" },
  { dataIndex: "May", title: "5月" },
  { dataIndex: "June", title: "6月" },
  { dataIndex: "July", title: "7月" },
  { dataIndex: "August", title: "8月" },
  { dataIndex: "September", title: "9月" },
  { dataIndex: "October", title: "10月" },
  { dataIndex: "November", title: "11月" },
  { dataIndex: "December", title: "12月" },
];

// 糞肥管理
const workHoursRow = [
  { key: "WorkerNumber", title: "WorkHour.WorkerNumber" },
  { key: "AverageHourPerDay", title: "WorkHour.AverageHourPerDay" },
  // {
  //   key: "ProportionOfWorkingHours",
  //   title: "WorkHour.ProportionOfWorkingHours",
  //   readonly: true,
  // },
  { key: "WorkDay", title: "WorkHour.WorkDay" },
  // { key: "Type", title: "人員類別" }, // 表格內無需出現
  { key: "Scalar", title: "WorkHour.Scalar" }, // 總工時

  { key: "OverTimeWorkerNumber", title: "WorkHour.OverTimeWorkerNumber" },
  {
    key: "OverTimeAverageHourPerDay",
    title: "WorkHour.OverTimeAverageHourPerDay",
  },
  { key: "OverTimeWorkDay", title: "WorkHour.OverTimeWorkDay" },
  {
    key: "OverTimeScalar",
    title: "WorkHour.OverTimeScalar",
    render: (text, record) => {
      return {
        props: {
          style: { background: "red" },
        },
        children: <div>{text}</div>,
      };
    },
  },
  { key: "TotalHours", title: "WorkHour.TotalHours", readonly: true },
  { key: "KgCO2e", title: "WorkHour.KgCO2e", readonly: true },
  { key: "Description", title: "CalculateForm.Description_B" },
  {
    title: "CalculateForm.referenceFile",
    key: "ReferenceFileLink",
  },
];
const WORKHOURS = (isEdit) => ({
  columns: MONTH.map(tableMonthGenerator),
  rows: workHoursRow.map((c) => tableItemGenerator(c, isEdit)),
});

// 冷媒
const refrigerantRow = [
  {
    title: "Refrigerant.ProcessName",
    key: "ProcessName",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "ProcessInfo.ProcessName",
    key: ['ProcessInformation', 'ProcessName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "EquipmentInfo.EquipmentName",
    key: ['EquipmentInformation', 'EquipmentName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "CombustiblesList.Name",
    key: ['CombustiblesList', 'DisplayName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Process.IsBiomassEnergy",
    key: "IsBiomassEnergy",
    width: 150,
    isIII: true,
    isNormal: false,
    render: (Value) => {
      return Value ? t("User.IsCompanyAdminYes") : t("User.IsCompanyAdminNo");
    },
  },
  {
    title: "Refrigerant.ResponsibleUnit",
    key: "ResponsibleUnit",
    width: 180,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.Name",
    key: "Name",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "Refrigerant.ModelNumber",
    key: "ModelNumber",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.ParameterID",
    key: "ParameterIDTitle", // 須查詢字典檔
    width: 200,
    isIII: false,
    isNormal: true,
  },
  {
    title: "Refrigerant.TotalNumber",
    key: "TotalNumber",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.Scalar",
    key: "OriScalar",
    width: 250,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Refrigerant.Scalar",
    key: "Scalar",
    width: 250,
    isIII: false,
    isNormal: true,
  },
  {
    title: "Kitchens.DistributionRatio",
    key: "DistributionRatioNumber",
    width: 200,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Refrigerant.UsedMonth",
    key: "UsedMonth",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.ParameterID2",
    key: "ParameterID2Title", // 須查詢字典檔
    width: 180,
    isIII: false,
    isNormal: true,
  },
  {
    title: "GWP",
    key: "GWP",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.factor",
    key: "factor",
    width: 170,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Refrigerant.KgCO2e",
    key: "KgCO2e",
    width: 200,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Buttons.Option",
    key: "option",
    fixed: "right",
    width: 150,
    isIII: true,
    isNormal: true,
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTREFRIGERANT_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEREFRIGERANT_API,
      },
    ],
  },
];
const REFRIGERANT = (isEdit) => ({
  columns: refrigerantRow.map((c) => tableItemGenerator(c, isEdit)),
});

// 用電量
const POWERMONTH = [
  {
    dataIndex: "Item_title",
    title: "項目",
    readonly: true,
    width: 200,
    className: "calculate-table",
    fixed: "left",
  },
  {
    dataIndex: "CrossYear1",
    title: "跨年帳單1",
    readonly: true,
  },
  { dataIndex: "January", title: "1月" },
  { dataIndex: "February", title: "2月" },
  { dataIndex: "March", title: "3月" },
  { dataIndex: "April", title: "4月" },
  { dataIndex: "May", title: "5月" },
  { dataIndex: "June", title: "6月" },
  { dataIndex: "July", title: "7月" },
  { dataIndex: "August", title: "8月" },
  { dataIndex: "September", title: "9月" },
  { dataIndex: "October", title: "10月" },
  { dataIndex: "November", title: "11月" },
  { dataIndex: "December", title: "12月" },
  {
    dataIndex: "CrossYear2",
    title: "跨年帳單2",
  },
];
const powerUsagesRow = [
  {
    title: "PowerUsages.Peak",
    key: "Peak",
  },
  {
    title: "PowerUsages.HalfPeak",
    key: "HalfPeak",
  },
  {
    title: "PowerUsages.SaturdayHalfPeak",
    key: "SaturdayHalfPeak",
  },
  {
    title: "PowerUsages.OffPeak",
    key: "OffPeak",
  },
  {
    title: "PowerUsages.Elecdeduct1",
    key: "Elecdeduct1",
  },
  {
    title: "PowerUsages.Elecdeduct2",
    key: "Elecdeduct2",
  },
  {
    title: "PowerUsages.Scalar",
    key: "Scalar",
    readonly: true,
  },
  {
    title: "PowerUsages.Split",
    key: "ResultsOfCrossYearBreakdown",
    readonly: true,
  },
  {
    title: "PowerUsages.BillSplit",
    key: "PercentageOfCrossYearBreakdownNumber",
    readonly: true,
  },
  {
    title: "PowerUsages.Description",
    key: "Description",
  },
  {
    title: "PowerUsages.KgCO2e",
    key: "KgCO2e",
  },
  {
    title: "PowerUsages.ReferenceFileLink",
    key: "ReferenceFileLink",
  },
  // {
  //   title: "CalculateForm.Description_B",
  //   key: "Description",
  // },
  {
    title: "PowerUsages.StartDate",
    key: "StartDate",
    // valueType: 'date'
  },
  {
    title: "PowerUsages.EndDate",
    key: "EndDate",
    // valueType: 'date'
  },
];
const POWERUSAGES = (isEdit) => ({
  columns: POWERMONTH.map(tableMonthGenerator),
  rows: powerUsagesRow.map((c) => tableItemGenerator(c, isEdit)),
});

const greenPowerUsagesRow = [
  {
    title: "GreenPowerUsages.Scalar",
    key: "Scalar",
  },
  // {
  //   title: "KgCO2e",
  //   key: "KgCO2e",
  // },
  {
    title: "GreenPowerUsages.Description",
    key: "Description",
  },
  {
    title: "GreenPowerUsages.ReferenceFileLink",
    key: "ReferenceFileLink",
  },
];
const GREENPOWERUSAGE = (isEdit) => ({
  columns: MONTH.map(tableMonthGenerator),
  rows: greenPowerUsagesRow.map((c) => tableItemGenerator(c, isEdit)),
});

// 車輛
const vehiclesCol = [
  {
    title: "Vehicles.Type",
    key: "Type",
    render: (_) => {
      const vehicleTypes = ["Vehicles.Company", "租賃、私人車輛"];
      return <>{t(vehicleTypes[_])}</>;
    },
    isIII: false,
    isNormal: true,
    fixed: "left",
  },
  {
    title: "Vehicles.DeviceName_SystemMenusID",
    key: "DeviceName_SystemMenusID",
    render: (value) => {
      const {
        source: { SystemMenus },
      } = store.getState();
      const text =
        (value && SystemMenus.find(({ ID }) => value === ID)?.SystemKey) || "";
      return text;
    },
    isIII: false,
    isNormal: false,
  },
  {
    title: "Vehicles.ProcessName",
    key: "ProcessName",
    width: 150,
    isIII: false,
    isNormal: true,
  },
  {
    title: "ProcessInfo.ProcessName",
    key: ['ProcessInformation', 'ProcessName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Vehicles.CarPlateNo",
    key: ['EquipmentInformation', 'ProductModel'],
    width: 180,
    isIII: true,
    isNormal: false,
  },
  {
    title: "EquipmentInfo.EquipmentName",
    key: ['EquipmentInformation', 'EquipmentName'],
    width: 180,
    isIII: true,
    isNormal: false,
  },
  {
    title: "CombustiblesList.Name",
    key: ['CombustiblesList', 'DisplayName'],
    width: 180,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Vehicles.IsBiomassEnergy",
    key: "IsBiomassEnergy",
    width: 150,
    isIII: true,
    isNormal: false,
    render: (Value) => {
      return Value ? t("User.IsCompanyAdminYes") : t("User.IsCompanyAdminNo");
    },
  },
  {
    title: "Vehicles.OriScalar",
    key: "OriScalar",
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Vehicles.DistributionRatio",
    key: "DistributionRatioNumber",
    width: 200,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Vehicles.ResponsibleUnit",
    key: "ResponsibleUnit",
    width: 150,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Vehicles.Name",
    key: "Name",
    width: 180,
    isIII: false,
    isNormal: true,
  },
  {
    title: "Vehicles.CarPlateNo",
    key: "CarPlateNo",
    isIII: false,
    isNormal: true,
  },
  {
    title: "Vehicles.Area",
    key: "area", // "台灣,移動源 - 柴油KgCO2e/L,2022" 取 台灣
    isIII: true,
    isNormal: true,
  },
  {
    title: "Vehicles.FuelType",
    key: "fuelType",
    isIII: true,
    isNormal: true,
  },
  {
    title: "CalculateForm.Year",
    key: "year",
    isIII: true,
    isNormal: true,
  },

  {
    title: "Vehicles.Scalar",
    key: "Scalar",
    isIII: false,
    isNormal: true,
  },
  {
    title: "Vehicles.ActivityIntensityUnit",
    key: "ActivityIntensityUnit",
    isIII: true,
    isNormal: true,
    width: 200,
  },
  {
    title: "Vehicles.Unit",
    key: "Unit",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Vehicles.factor",
    key: "factor",
    isIII: true,
    isNormal: true,
    width: 180,
  },
  {
    title: "Vehicles.KgCO2e",
    key: "KgCO2e",
    isIII: true,
    isNormal: true,
    width: 200,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    isIII: true,
    isNormal: true,
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTVEHICLE_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEVEHICLE_API,
      },
    ],
  },
];
const VEHICLES = (isEdit) => ({
  columns: vehiclesCol.map((c) => tableItemGenerator(c, isEdit)),
});

// 柴油設備 油氣燃料
const kitchensCol = [
  {
    title: "Kitchens.ProcessName",
    key: "ProcessName",
    width: 180,
    isIII: false,
    isNormal: true,
    fixed: "left",
  },
  {
    title: "ProcessInfo.ProcessName",
    key: ['ProcessInformation', 'ProcessName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "EquipmentInfo.EquipmentName",
    key: ['EquipmentInformation', 'EquipmentName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "CombustiblesList.Name",
    key: ['CombustiblesList', 'DisplayName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Kitchens.IsBiomassEnergy",
    key: "IsBiomassEnergy",
    width: 150,
    isIII: true,
    isNormal: false,
    render: (Value) => {
      return Value ? t("User.IsCompanyAdminYes") : t("User.IsCompanyAdminNo");
    },
  },
  {
    title: "Kitchens.ResponsibleUnit",
    key: "ResponsibleUnit",
    width: 180,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.Name",
    key: "Name",
    width: 180,
    isIII: false,
    isNormal: true,
  },
  {
    title: "Vehicles.DeviceName_SystemMenusID",
    key: "DeviceName_SystemMenusID",
    render: (value) => {
      const {
        source: { SystemMenus },
      } = store.getState();
      const text =
        (value && SystemMenus.find(({ ID }) => value === ID)?.SystemKey) || "";
      return text;
    },
    isIII: false,
    isNormal: false,
  },
  {
    title: "Kitchens.Area",
    key: "area",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.FuelType",
    key: "fuelType",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.Year",
    key: "year",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.Unit",
    key: "Unit",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.Scalar",
    key: "Scalar",
    isIII: false,
    isNormal: true,
    width: 180,
  },
  {
    title: "Kitchens.OriScalar",
    key: "OriScalar",
    isIII: true,
    isNormal: false,
    width: 180,
  },
  {
    title: "Kitchens.DistributionRatio",
    key: "DistributionRatioNumber",
    width: 200,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Kitchens.ActivityIntensityUnit",
    key: "ActivityIntensityUnit",
    isIII: true,
    isNormal: true,
    width: 200,
  },
  {
    title: "Kitchens.factor",
    key: "factor",
    isIII: true,
    isNormal: true,
  },
  {
    title: "Kitchens.KgCO2e",
    key: "KgCO2e",
    isIII: true,
    isNormal: true,
    width: 170,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    isIII: true,
    isNormal: true,
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTKITCHEN_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEKITCHEN_API,
      },
    ],
  },
];
const KITCHENS = (isEdit) => ({
  columns: kitchensCol.map((c) => tableItemGenerator(c, isEdit)),
});

// 消防
const fireEquipmentsCol = [
  {
    title: "FireEquipment.ProcessName",
    key: "ProcessName",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "ProcessInfo.ProcessName",
    key: ['ProcessInformation', 'ProcessName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "EquipmentInfo.EquipmentName",
    key: ['EquipmentInformation', 'EquipmentName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "CombustiblesList.Name",
    key: ['CombustiblesList', 'DisplayName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "Process.IsBiomassEnergy",
    key: "IsBiomassEnergy",
    width: 150,
    isIII: true,
    isNormal: false,
    render: (Value) => {
      return Value ? t("User.IsCompanyAdminYes") : t("User.IsCompanyAdminNo");
    },
  },
  {
    title: "FireEquipment.ResponsibleUnit",
    key: "ResponsibleUnit",
    width: 180,
    isIII: true,
    isNormal: true,
  },
  {
    title: "FireEquipment.Name",
    key: "Name",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "FireEquipment.Quantity",
    key: "Quantity",
    isIII: false,
    isNormal: true,
  },
  {
    title: "FireEquipment.OriginalFill",
    key: "OriginalFill",
    isIII: false,
    isNormal: true,
  },
  {
    title: "FireEquipment.ParameterID",
    key: "ParameterIDTitle",
    isIII: false,
    isNormal: true,
  },
  {
    title: "FireEquipment.Scalar",
    key: "OriScalar",
    isIII: true,
    isNormal: false,
  },
  {
    title: "FireEquipment.Scalar",
    key: "Scalar",
    isIII: false,
    isNormal: true,
  },
  {
    title: "Kitchens.DistributionRatio",
    key: "DistributionRatioNumber",
    width: 200,
    isIII: true,
    isNormal: false,
  },
  {
    title: "GWP",
    key: "factor",
    isIII: true,
    isNormal: true,
  },
  {
    title: "FireEquipment.KgCO2e",
    key: "KgCO2e",
    width: 200,
    isIII: true,
    isNormal: true,
  },
  {
    title: "FireEquipment.Description",
    key: "Description",
    ellipsis: true,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    isIII: true,
    isNormal: true,
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTFIREEQUIPMENT_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEFIREEQUIPMENT_API,
      },
    ],
  },
];
const FIREEQUIPMENTS = (isEdit) => ({
  columns: fireEquipmentsCol.map((c) => tableItemGenerator(c, isEdit)),
});

// 蒸氣量
const steamUsagesRow = [
  {
    title: "SteamUsages.Scalar",
    key: "Scalar",
  },
  {
    title: "SteamUsages.KgCO2e",
    key: "KgCO2e",
  },
  {
    title: "SteamUsages.Description",
    key: "Description",
  },
  {
    title: "SteamUsages.ReferenceFileLink",
    key: "ReferenceFileLink",
  },
];
const STEAMUSAGES = (isEdit) => ({
  columns: MONTH.map(tableMonthGenerator),
  rows: steamUsagesRow.map((c) => tableItemGenerator(c, isEdit)),
});

const otherCompoundsCol = [
  {
    title: "OtherCompounds.ProcessName",
    key: "ProcessName",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "ProcessInfo.ProcessName",
    key: ['ProcessInformation', 'ProcessName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "EquipmentInfo.EquipmentName",
    key: ['EquipmentInformation', 'EquipmentName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  {
    title: "CombustiblesList.Name",
    key: ['CombustiblesList', 'DisplayName'],
    width: 150,
    isIII: true,
    isNormal: false,
  },
  // {
  //   title: "Process.IsBiomassEnergy",
  //   key: "IsBiomassEnergy",
  //   width: 150,
  //   isIII: true,
  //   isNormal: false,
  //   render: (Value) => {
  //     return Value ? t("User.IsCompanyAdminYes") : t("User.IsCompanyAdminNo");
  //   },
  // },
  {
    title: "OtherCompounds.ResponsibleUnit",
    key: "ResponsibleUnit",
    width: 180,
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Name",
    key: "Name",
    width: 180,
    fixed: "left",
    isIII: false,
    isNormal: true,
  },
  {
    title: "OtherCompounds.warmGasType",
    key: "warmGasType",
    render: (warmGasType) => {
      const {
        source: { SystemMenus },
      } = store.getState();
      const text =
        (warmGasType &&
          SystemMenus.find(({ value }) => value === warmGasType)?.label) ||
        "";
      return text;
    },
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Quantity",
    key: "Quantity",
    isIII: false,
    isNormal: true,
  },
  {
    title: "OtherCompounds.ParameterID",
    key: "ParameterIDTitle",
    isIII: false,
    isNormal: true,
  },
  {
    title: "OtherCompounds.ingredientName",
    key: "ingredientName",
    width: 180,
    isIII: false,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Scalar",
    key: "Scalar",
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.ActivityIntensityUnit",
    key: "ActivityIntensityUnit",
    width: 200,
    isIII: true,
    isNormal: true,
  },
  {
    // 係數
    title: "OtherCompounds.KgCO2e",
    key: "CO2Factor",
    width: 170,
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Unit",
    key: "Unit",
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.GWPFactor",
    key: "GWPFactor",
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Emission",
    key: "KgCO2e",
    width: 170,
    isIII: true,
    isNormal: true,
  },
  {
    title: "OtherCompounds.Description",
    key: "Description",
    ellipsis: true,
    isIII: true,
    isNormal: true,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    isIII: true,
    isNormal: true,
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTOTHERCOMPOUND_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEOTHERCOMPOUND_API,
      },
    ],
  },
];

const OTHERCOMPOUNDS = (isEdit) => ({
  columns: otherCompoundsCol.map((c) => tableItemGenerator(c, isEdit)),
});

// lifeCycle
const lifeCycleCol = [
  {
    title: "LifeCycle.RiskAssmtMaterialID",
    key: "GHGEvaluateItem",
    fixed: "left",
  },
  {
    title: "LifeCycle.MaterialNo",
    key: "MaterialNo",
    fixed: "left",
  },
  {
    title: "CalculateForm.Scalar_B",
    key: "ActivityIntensity",
  },
  {
    title: "LifeCycle.ActivityIntensityUnit",
    key: "ActivityIntensityUnit",
    width: 190,
  },
  {
    title: "LifeCycle.KgCO2e",
    key: "KgCO2e",
    width: 165,
  },
  {
    title: "LifeCycle.ParameterID",
    key: "Encoding",
    width: 165,
  },
  {
    title: "LifeCycle.Unit",
    key: "Unit",
  },
  {
    title: "LifeCycle.EmissionKg",
    key: "EmissionKg",
    width: 200,
  },
  // {
  //   title: "LifeCycle.Percentage",
  //   key: "Percentage",
  //   width: 130,
  // },
  {
    title: "LifeCycle.ParameterDescription",
    key: "Instruction",
    ellipsis: true,
    width: 300,
  },
  {
    title: "LifeCycle.Uncertainty",
    key: "UncertaintyAnalysis",
    width: 150,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTLIFECYCLE_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETELIFECYCLE_API,
      },
    ],
  },
];
const LIFYCYCLE = (isEdit) => ({
  columns: lifeCycleCol.map((c) => tableItemGenerator(c, isEdit)),
});

// lifeCycleForPeople
const lifeCycleForPeopleCol = [
  {
    title: "LifeCycle.RiskAssmtMaterialID",
    key: "GHGEvaluateItem",
    fixed: "left",
  },
  {
    title: "CalculateForm.Scalar_B",
    key: "ActivityIntensity",
  },
  {
    title: "CalculateForm.KgCO2e",
    key: "KgCO2e",
  },
  {
    title: "CalculateForm.Unit",
    key: "Unit",
  },
  {
    title: "LifeCycle.EmissionKg",
    key: "EmissionKg",
    width: 200,
  },
  // {
  //   title: "LifeCycle.Percentage",
  //   key: "Percentage",
  // },
  {
    title: "LifeCycle.ParameterDescription",
    key: "Instruction",
    ellipsis: true,
  },
  {
    title: "LifeCycle.Uncertainty",
    key: "UncertaintyAnalysis",
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTLIFECYCLE_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETELIFECYCLE_API,
      },
    ],
  },
];
const LIFYCYCLEFORPEOPLE = (isEdit) => ({
  columns: lifeCycleForPeopleCol.map((c) => tableItemGenerator(c, isEdit)),
});

// 員工通勤
const commutingCol = (param) => [
  {
    title: "Commuting.Transportation",
    key: "StrCommuting",
    fixed: "left",
  },
  {
    title: "Commuting.TotalEmployees",
    key: "TotalEmployees",
    width: 200,
  },
  {
    title:
      param === "Commuting"
        ? "Commuting.WorkingDays"
        : param === "Visitor"
        ? "Commuting.VisitsDays"
        : "Commuting.TripsDays",
    key:"WorkingDays",
    width: 165,
  },
  {
    title: "Commuting.AverageMovingDistance",
    key: "AverageMovingDistance",
    width: 200,
  },
  // {
  //   title: "Commuting.RoundTrip",
  //   key: "StrRoundTrip",
  // },
  {
    title: "Commuting.Scenario",
    key: "MovingScenario",
  },
  {
    title: "Commuting.Unit",
    key: "CoefficienUnit",
  },
  {
    title: "Commuting.KgCO2e",
    key: "Coefficient",
    width: 200,
  },
  {
    title: "LifeCycle.ParameterID",
    key: "Encoding",
    width: 170,
  },
  {
    title: "Commuting.ParameterDescription",
    key: "CoefficientNote",
    ellipsis: true,
  },
  {
    title: "Commuting.Emission",
    key: "KgCO2e",
    width: 200,
  },
  {
    title: "LifeCycle.Uncertainty",
    key: "UncertaintyAnalysis",
    width: 160,
  },
  {
    title: "Buttons.Option",
    key: "option",
    fixed: "right",
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: POSTREFRIGERANT_API,
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTCOMMUTING_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETECOMMUTING_API,
      },
    ],
  },
];
const COMMUTING = (isEdit, param) => ({
  columns: commutingCol(param).map((c) => tableItemGenerator(c, isEdit)),
})

// 原料採購
const purchasedProductCol = [
  {
    title: "PurchasedGood.MaterialNo",
    key: "MaterialNo",
    fixed: "left",
  },
  {
    title: "PurchasedGood.MaterialName",
    key: "MaterialName",
    fixed: "left",
  },
  {
    title: "PurchasedGood.MaterialSpec",
    key: "MaterialSpec",
  },
  {
    title: "PurchasedGood.AnnualPurchaseAmount",
    key: "AnnualPurchaseAmount",
  },
  {
    title: "PurchasedGood.Scalar",
    key: "ActivityIntensity",
  },
  {
    title: "PurchasedGood.ActivityIntensityUnit",
    key: "ActivityIntensityUnit",
  },
  {
    title: "PurchasedGood.KgCO2e",
    key: "ParameterValue",
  },
  {
    title: "LifeCycle.ParameterID",
    key: "Encoding",
  },
  {
    title: "PurchasedGood.Unit",
    key: "Unit",
  },
  {
    title: "PurchasedGood.EmissionKg",
    key: "KgCO2e",
    width: 200,
  },
  // {
  //   title: "LifeCycle.Percentage",
  //   key: "Percentage",
  //   width: 130,
  // },
  {
    title: "PurchasedGood.Uncertainty",
    key: "UncertaintyAnalysis",
    width: 150,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: "",
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTPURCHASEDPRODUCT_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEPURCHASEDPRODUCT_API,
      },
    ],
  },
];
const PURCHASEDPRODUCT = (isEdit) => ({
  columns: purchasedProductCol.map((c) => tableItemGenerator(c, isEdit)),
});

// 上游運輸
const upstreamTransportCol = [
  {
    title: "UpstreamTransport.RiskAssmtMaterialID",
    key: "CargoName",
    width: 180,
    fixed: "left",
  },
  {
    title: "UpstreamTransport.JourneyNO",
    key: "JourneyNO",
    fixed: "left",
  },
  {
    title: "UpstreamTransport.StartLocation",
    key: "StartLocation",
  },
  {
    title: "UpstreamTransport.EndLocation",
    key: "EndLocation",
  },
  {
    title: "UpstreamTransport.TransportDistance",
    key: "TransportDistance",
    width: 150,
  },
  {
    title: "UpstreamTransport.TransportWeight",
    key: "TransportWeight",
  },
  {
    title: "UpstreamTransport.KgCO2e",
    key: "ParameterValue",
    width: 200,
  },
  {
    title: "LifeCycle.ParameterID",
    key: "Encoding",
  },
  {
    title: "UpstreamTransport.ScalarUnit",
    key: "ScalarUnit",
    width: 200,
  },
  {
    title: "Ton*Km",
    key: "Scalar",
  },
  {
    title: "UpstreamTransport.Emission",
    key: "KgCO2e",
    width: 200,
  },
  {
    title: "UpstreamTransport.Uncertainty",
    key: "UncertaintyAnalysis",
    width: 150,
  },
  {
    title: "Buttons.Option",
    key: "option",
    width: 150,
    fixed: "right",
    render: [
      {
        text: t("Buttons.DownloadFile"),
        key: "download",
        fn: download,
        apiPath: POSTREFRIGERANT_API,
      },
      {
        text: t("Buttons.Edit"),
        key: "edit",
        fn: edit,
        apiPath: POSTUPSTREAMTRANSPORT_API,
      },
      {
        text: t("Buttons.Delete"),
        key: "del",
        fn: showDeleteConfirm,
        apiPath: DELETEUPSTREAMTRANSPORT_API,
      },
    ],
  },
];
const UPSTREAMTRANSPORT = (isEdit) => ({
  columns: upstreamTransportCol.map((c) => tableItemGenerator(c, isEdit)),
});


export {
  WORKHOURS,
  REFRIGERANT,
  POWERUSAGES,
  GREENPOWERUSAGE,
  VEHICLES,
  KITCHENS,
  FIREEQUIPMENTS,
  STEAMUSAGES,
  OTHERCOMPOUNDS,
  LIFYCYCLE,
  COMMUTING,
  PURCHASEDPRODUCT,
  UPSTREAMTRANSPORT,
  LIFYCYCLEFORPEOPLE,
};
