//+新增的表單
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProFormDigit,
  ProFormUploadButton,
  ProFormSelect,
  ProFormText,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  FileSearchOutlined,
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Form, Cascader, Input, Space, Select, Divider } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import axios from "axios";
import {
  formatScalar,
  formatKgCO2e,
  findParameterID,
  findAreaAndYear,
  messageGenerator,
} from "@UTIL";
import { store, setSystemMenus } from "@STORE";
import {
  GET_III_SYSTEMTYPE,
  CREATE_III_SYSTEMTYPE,
  UPDATE_III_SYSTEMTYPE,
  DELETE_III_SYSTEMTYPE,
} from "@CON";

const SystemTypes = {
  StationaryCombustion: 1,
  MobileCombustion: 2,
  IndustrialProcesses: 3,
  AnthropogenicFugitive: 4,
  PowerUsage: 5,
  SteamUsage: 6,
};

// 將標籤轉換成多國語系
const NameTranslate = ({ name }) => {
  const { t } = useTranslation();
  return t(name);
};

const changeItemBorderColor = (value, { ActivityIntensityUnit, Unit }) => {
  if (ActivityIntensityUnit && Unit) {
    if (ActivityIntensityUnit.toLowerCase() === Unit.toLowerCase()) {
      return Promise.resolve(value);
    } else {
      return Promise.reject(t("FormMsg.InconsistentUnit"));
    }
  } else if (!ActivityIntensityUnit) {
    return Promise.resolve(value);
  }
};

const WarningText = ({ form }) => {
  const values = Form.useWatch([], form);
  useEffect(() => {
    if (values && values.ActivityIntensityUnit && values.Unit) {
      form
        ?.validateFields(["Unit"], {
          validateOnly: true,
        })
        .then((values) => {
          return Promise.resolve(values);
        })
        .catch((err) => () => {
          return Promise.reject(values);
        });
    }
  });
  return <></>;
};

const SystemMenusDropDown = ({ form, SystemTypeID, param }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    source: { SystemMenus },
    user: {
      SiteUser,
      userInfo: { Company },
    },
  } = useSelector((state) => state);
  const [btnType, setBtnType] = useState("add"); // add edit save
  const [menuKeyText, setMenuKeyText] = useState("");
  const [editId, setEditId] = useState(null);

  const handleSystemMenusIDClear = () => {
    setBtnType("add");
    setMenuKeyText("");
    setEditId(null);
  };

  // 新增
  const addItem = async (value) => {
    const res = await axios.post(CREATE_III_SYSTEMTYPE, {
      CompanyID: Company.ID,
      SystemTypeID: SystemTypeID,
      SystemKey: value, // 名稱
    });
    if (res.result === "success") {
      messageGenerator({
        result: res.result,
        content: t("MessageText.Add successful"),
      });
      dispatch(setSystemMenus([...SystemMenus, res.data]));
      form.setFieldValue("DeviceName_SystemMenusID", res.data.ID);
      handleSystemMenusIDClear();
    } else {
      messageGenerator({ content: t("MessageText.Add failed") });
    }
  };

  // 編輯
  // 定義一個異步函數 editItem，用來編輯某一個項目
  const editItem = async (editId, value) => {
    // 使用 axios 發送 POST 請求，更新系統類型
    const res = await axios.post(UPDATE_III_SYSTEMTYPE, {
      ID: editId, // 編輯項目的 ID
      CompanyID: Company.ID, // 公司 ID
      SystemTypeID, // 系統類型 ID
      SystemKey: value, // 要更新的名稱（SystemKey）
    });

    // 過濾掉當前編輯的項目，剩下的項目保存在 items 中
    const items = SystemMenus.filter(({ ID }) => ID !== editId);

    // 根據回傳結果判斷是否編輯成功
    if (res.result === "success") {
      // 顯示編輯成功的訊息
      messageGenerator({
        result: res.result,
        content: t("MessageText.Edit successful"), // 成功訊息
      });

      // 更新 Redux 狀態，將新的資料 res.data 合併到 SystemMenus 中
      dispatch(setSystemMenus([...items, res.data]));

      // 更新表單中的 DeviceName_SystemMenusID 字段為 editId
      form.setFieldValue("DeviceName_SystemMenusID", editId);

      // 清除系統選單的 ID 選擇
      handleSystemMenusIDClear();
    } else {
      // 若編輯失敗，顯示錯誤訊息
      messageGenerator({ content: t("MessageText.Edit failed") });
    }
  };

  // 定義一個函數 handleSave，用來處理保存操作
  const handleSave = () => {
    // 從表單中取得 SystemKey 的值
    const SystemKey = form.getFieldValue("SystemKey");

    // 檢查 SystemKey 是否有值，如果沒有則顯示必填訊息
    if (!SystemKey) {
      messageGenerator({ content: t("FormMsg.Required") });
      return;
    }

    // 如果有 editId，表示是編輯操作，則呼叫 editItem 函數
    if (editId) {
      editItem(editId, SystemKey);
    } else {
      // 否則，表示是新增操作，則呼叫 addItem 函數
      addItem(SystemKey);
    }
  };

  const deleteItem = async (e, deleteID) => {
    e.stopPropagation();

    const res = await axios.delete(DELETE_III_SYSTEMTYPE, {
      data: {
        ID: deleteID.toString(),
      },
    });
    if (res.result === "success") {
      messageGenerator({
        result: res.result,
        content: t("MessageText.Delete successful"),
      });
      dispatch(
        setSystemMenus([...SystemMenus.filter(({ ID }) => deleteID !== ID)])
      );
    } else {
      messageGenerator({
        result: res.result,
        content: t("MessageText.Delete failed"),
        description: res.errMsg.split(","),
      });
    }
    handleSystemMenusIDClear();
  };

  return SiteUser === "III" ? (
    <Form.Item
      label="設備類別"
      name="DeviceName_SystemMenusID"
      rules={[
        {
          required: true,
          message: t("FormMsg.Required"),
        },
      ]}
    >
      <Space.Compact block className="w-100 mb-0">
        {btnType === "save" ? (
          <Form.Item name="SystemKey" className="w-100 mb-0">
            <Input />
          </Form.Item>
        ) : (
          <Form.Item name="DeviceName_SystemMenusID" className="w-100 mb-0">
            <Select
              showSearch
              allowClear
              placeholder={t("Quote.Select")}
              onClear={handleSystemMenusIDClear}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionLabelProp="label"
            >
              {SystemMenus.map(({ ID, SystemKey, CompanyID }) => (
                <Select.Option
                  value={ID}
                  key={ID}
                  title={SystemKey}
                  label={SystemKey}
                >
                  <span className="d-flex justify-content-between">
                    {SystemKey}
                    <span>
                      {CompanyID && (
                        <>
                          <EditOutlined
                            className="me-3"
                            onClick={() => {
                              form.setFieldValue("SystemKey", SystemKey);
                              setEditId(ID);
                              setBtnType("save");
                            }}
                          />
                          <DeleteOutlined
                            className="text-danger"
                            onClick={(e) => deleteItem(e, ID)}
                          />
                        </>
                      )}
                    </span>
                  </span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item className="mb-0">
          {btnType === "add" && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="d-flex align-items-center"
              onClick={() => {
                form.setFieldValue("SystemKey", "");
                setBtnType("save");
              }}
            >
              {t("Buttons.Add")}
            </Button>
          )}
          {/* {btnType === "edit" && (
            <Button
              type="primary"
              icon={<EditOutlined />}
              className="d-flex align-items-center"
              onClick={() => {
                form.setFieldValue("SystemKey", menuKeyText);
                setBtnType("save");
              }}
            >
              編輯
            </Button>
          )} */}

          {btnType === "save" && (
            <Button
              type="primary"
              icon={<SaveOutlined />}
              className="d-flex align-items-center"
              onClick={handleSave}
            >
              {t("Buttons.Save")}
            </Button>
          )}
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            danger
            icon={<RollbackOutlined />}
            className="d-flex align-items-center"
            disabled={btnType !== "save"}
            onClick={() => {
              menuKeyText && setBtnType("edit");
              !menuKeyText && setBtnType("add");
            }}
          >
            {t("Buttons.Cancel")}
          </Button>
        </Form.Item>
      </Space.Compact>
    </Form.Item>
  ) : param === "Vehicles" ? (
    <ProFormSelect
      allowClear={false}
      key={uuidv4()}
      colProps={{ md: 8 }}
      initialValue={0}
      options={[
        {
          value: 0,
          label: <NameTranslate name="Vehicles.Company" />,
        },
        // {
        //   value: 1,
        //   label: "租賃、私人車輛",
        // },
      ]}
      name="Type"
      label={<NameTranslate name="Vehicles.Type" />}
    />
  ) : (
    <></>
  );
};

const VEHICLES_ADD_FORM = ({
  parameterIDOptions,
  activityDataTypeOptions,
  emitParaTypeOptions,
  instrumentCalibrationSetOptions,
  form,
  param,
  setCodeModalType,
}) => [
  {
    label: "車輛類別",
    key: "type",
    Node: (
      <SystemMenusDropDown
        key={uuidv4()}
        form={form}
        SystemTypeID={SystemTypes.MobileCombustion}
        param={param}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "廠區 / 製程別",
    key: "ProcessName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ProcessName"
        label={<NameTranslate name="CalculateForm.ProcessName" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "製程",
    key: "ProcessInformation",
    Node: (
      <ProCard
        bordered
        title={t("ProcessInfo.Process")}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("processList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("ProcessInfo.ProcessTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="ProcessInfo.SerialNumber" />}
            tooltip={t("ProcessInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessCode"]}
            label={<NameTranslate name="ProcessInfo.Code" />}
            tooltip={t("ProcessInfo.Instructions2")}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessName"]}
            label={<NameTranslate name="ProcessInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "設備",
    key: "EquipmentInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="EquipmentInfo.Equipment" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("equipmentList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("EquipmentInfo.EquipmentTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="EquipmentInfo.SerialNumber" />}
            tooltip={t("EquipmentInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "ProductModel"]}
            label={t("Vehicles.CarPlateNo")}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentCode"]}
            label={<NameTranslate name="EquipmentInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("EquipmentInfo.Instructions2")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentName"]}
            label={<NameTranslate name="EquipmentInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "原燃物料或產品",
    key: "CombustiblesList",
    Node: (
      <ProCard
        bordered
        title={
          <NameTranslate name="CombustiblesList.RawFuelMaterialsProducts" />
        }
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("combustiblesList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CombustiblesList.RawFuelTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name="CombustibleCode"
            label={<NameTranslate name="CombustiblesList.CombustibleCode" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={<NameTranslate name="CombustiblesList.Instructions" />}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["CombustiblesList", "DisplayNameTW"]}
            label={<NameTranslate name="CombustiblesList.DisplayName" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬生質能源",
    key: "IsBiomassEnergy",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsBiomassEnergy"
        label={<NameTranslate name="Vehicles.IsBiomassEnergy" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬汽電共生設備",
    key: "IsCogenerationEquipment",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsCogenerationEquipment"
        label={<NameTranslate name="Vehicles.IsCogenerationEquipment" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動數據",
    key: "OriScalar",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Vehicles.OriScalar" />}
        name="OriScalar"
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
        fieldProps={{ precision: 4 }}
        min={0}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動數據分配比率%",
    key: "DistributionRatioNumber",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Vehicles.DistributionRatioNumber" />}
        name="DistributionRatioNumber"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
        initialValue={100}
        addonAfter="%"
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "公務車廠 / 車型",
    key: "Name",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Name"
        label={<NameTranslate name="Vehicles.Name" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "車牌號碼",
    key: "CarPlateNo",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="CarPlateNo"
        label={<NameTranslate name="Vehicles.CarPlateNo" />}
        rules={[{ required: true, message: t("FormMsg.RequiredLicense") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "地區", // 表單項目的標籤名稱
    key: "Area", // 唯一標識該表單key
    Node: (
      <Form.Item
        key={uuidv4()} // 使用唯一鍵值來標識此項目，防止重複
        label={<NameTranslate name="Vehicles.Area" />} // 顯示表單項目名稱，支持多語言
        name="Area" // 表單項目的名稱，用於與表單數據綁定
        rules={[
          {
            required: true, // 驗證規則：此項為必填
            message: <NameTranslate name="FormMsg.Required" />, // 必填項未填時顯示的錯誤訊息
          },
        ]}
      >
        <Cascader // 多層選擇器
          placement="bottom" // 設定選項面板出現的位置
          showSearch={{
            // 允許用戶通過輸入文字來搜索選項
            filter: (inputValue, path) =>
              path.some(
                (option) =>
                  option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                  -1
              ),
          }}
          options={parameterIDOptions} // 提供層級選項數據
        />
      </Form.Item>
    ),
    isIII: true, // 此表單項目屬於 III 類
    isNormal: true, // 此表單項目屬於正常類
  },
  {
    label: "係數單位",
    key: "Unit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Unit"
        label={<NameTranslate name="Vehicles.Unit" />}
        disabled
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "使用量",
    key: "Scalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={
          <span className="d-flex flex-column">
            <NameTranslate name="Vehicles.Scalar" />
            <small className="text-info">
              <NameTranslate name="Vehicles.ScalarNote" />
            </small>
          </span>
        }
        name="Scalar"
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve(value);
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
        fieldProps={{ precision: 4 }}
        min={0}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ActivityIntensityUnit"
        label={<NameTranslate name="Vehicles.ActivityIntensityUnit" />}
        rules={[
          {
            warningOnly: true,
            message: t("FormMsg.InconsistentUnit"),
            validator: (_, value) => {
              const Unit = form?.getFieldValue("Area")[1].split("/")[1];
              return changeItemBorderColor(value, {
                ActivityIntensityUnit: value,
                Unit,
              });
            },
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "負責單位",
    key: "ResponsibleUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ResponsibleUnit"
        label={<NameTranslate name="CalculateForm.ResponsibleUnit" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "加油單據或加油紀錄",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="Vehicles.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        // action={UPLOAD_REFERENCE_API}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "公務車照片(背面)",
    key: "referencePhoto",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="Vehicles.referencePhoto" />}
        name="referencePhoto"
        title={<NameTranslate name="Buttons.Upload" />}
        // action={UPLOAD_REFERENCE_API}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referencePhoto", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referencePhoto", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "使用量佐證說明",
    key: "Evidence",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 6 }}
        name="Evidence"
        label={<NameTranslate name="CalculateForm.Evidence" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "燃料熱值數值",
    key: "heatValue",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="CalculateForm.FuelCalorificValue" />}
        name="heatValue"
        fieldProps={{
          precision: 4,
        }}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "燃料熱值單位",
    key: "HeatValueUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="HeatValueUnit"
        label={<NameTranslate name="CalculateForm.FuelCalorificValueUnit" />}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "備註",
    key: "Description",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Description"
        label={<NameTranslate name="CalculateForm.Description" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "活動數據種類",
    key: "activityDataType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={activityDataTypeOptions}
        name="activityDataType"
        label={<NameTranslate name="Vehicles.activityDataType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放係數種類",
    key: "emitParaType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={emitParaTypeOptions}
        name="emitParaType"
        label={<NameTranslate name="Vehicles.emitParaType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "校正儀器種類",
    key: "InstrumentCalibrationSet",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={instrumentCalibrationSetOptions}
        name="InstrumentCalibrationSet"
        label={<NameTranslate name="Vehicles.InstrumentCalibrationSet" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
];

const REFRIGERANT_ADD_FORM = ({
  parameterIDOptions,
  parameterID2Options,
  activityDataTypeOptions,
  emitParaTypeOptions,
  instrumentCalibrationSetOptions,
  EmissionDescriptionOptions,
  ActivityUnitOptions,
  form,
  setCodeModalType,
}) => [
  {
    label: "廠區 / 製程別",
    key: "ProcessName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ProcessName"
        label={<NameTranslate name="Refrigerant.ProcessName" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "製程",
    key: "ProcessInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="ProcessInfo.Process" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("processList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("ProcessInfo.ProcessTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="ProcessInfo.SerialNumber" />}
            tooltip={t("ProcessInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessCode"]}
            label="代碼"
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("ProcessInfo.Instructions2")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessName"]}
            label={<NameTranslate name="ProcessInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "設備",
    key: "EquipmentInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="EquipmentInfo.Equipment" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("equipmentList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("EquipmentInfo.EquipmentTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="EquipmentInfo.SerialNumber" />}
            tooltip={t("EquipmentInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "ProductModel"]}
            label={<NameTranslate name="EquipmentInfo.ModelNumber" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentCode"]}
            label={<NameTranslate name="EquipmentInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("EquipmentInfo.Instructions2")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentName"]}
            label={<NameTranslate name="EquipmentInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "原燃物料或產品",
    key: "CombustiblesList",
    Node: (
      <ProCard
        bordered
        title={
          <NameTranslate name="CombustiblesList.RawFuelMaterialsProducts" />
        }
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("combustiblesList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CombustiblesList.RawFuelTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name="CombustibleCode"
            label={<NameTranslate name="CombustiblesList.CombustibleCode" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("CombustiblesList.Instructions")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["CombustiblesList", "DisplayNameTW"]}
            label={<NameTranslate name="CombustiblesList.DisplayName" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬生質能源",
    key: "IsBiomassEnergy",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsBiomassEnergy"
        label={<NameTranslate name="Process.IsBiomassEnergy" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬汽電共生設備",
    key: "IsCogenerationEquipment",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsCogenerationEquipment"
        label={<NameTranslate name="Process.IsCogenerationEquipment" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "逸散類別",
    key: "EmissionDescription",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={EmissionDescriptionOptions}
        name="EmissionDescription"
        label={<NameTranslate name="Process.EmissionCategory" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "負責單位",
    key: "ResponsibleUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ResponsibleUnit"
        label={<NameTranslate name="Refrigerant.ResponsibleUnit" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備名稱",
    key: "Name",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Name"
        label={<NameTranslate name="Refrigerant.Name" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "型號",
    key: "ModelNumber",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ModelNumber"
        label={<NameTranslate name="Refrigerant.ModelNumber" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用冷媒 / 製冷劑種類",
    key: "ParameterID",
    Node: (
      <ProFormSelect
        allowClear={false}
        showSearch
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={parameterIDOptions}
        name="ParameterID"
        label={<NameTranslate name="Refrigerant.ParameterID" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "全廠台數",
    key: "TotalNumber",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Refrigerant.TotalNumber" />}
        name="TotalNumber"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "原始填充量",
    key: "OriScalar",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="FireEquipment.OriginalFill" />}
        name="OriScalar"
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          { required: true, message: t("FormMsg.Required") },
        ]}
        fieldProps={{ precision: 4 }}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "冷媒 / 製冷劑原始填充量(kg)",
    key: "Scalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Refrigerant.Scalar" />}
        name="Scalar"
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          { required: true, message: t("FormMsg.Required") },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用月數",
    key: "UsedMonth",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<span className="d-flex flex-column">
          {<NameTranslate name="Refrigerant.UsedMonth" />}
          <small className="text-info">
            {<NameTranslate name="Refrigerant.UsedMonthNote" />}
          </small>
        </span>
      }
        name="UsedMonth"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
        max={12}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備類型(排放因子)",
    key: "ParameterID2",
    Node: (
      <ProFormSelect
        allowClear={false}
        showSearch
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={parameterID2Options}
        name="ParameterID2"
        label={<NameTranslate name="Refrigerant.ParameterID2" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動數據分配比率%",
    key: "DistributionRatioNumber",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Process.DistributionRatioNumber" />}
        name="DistributionRatioNumber"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
        initialValue={100}
        addonAfter="%"
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={ActivityUnitOptions}
        name="ActivityIntensityUnit"
        label={<NameTranslate name="Refrigerant.ActivityIntensityUnit" />}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="CalculateForm.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備照片",
    key: "referencePhoto",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="Refrigerant.ReferenceServicePhotoUrl" />}
        name="referencePhoto"
        title={<NameTranslate name="Buttons.Upload" />}
        // action={UPLOAD_REFERENCE_API}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referencePhoto", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referencePhoto", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "備註",
    key: "Description",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Description"
        label={<NameTranslate name="CalculateForm.Description" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "活動數據種類",
    key: "activityDataType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={activityDataTypeOptions}
        name="activityDataType"
        label={<NameTranslate name="CalculateForm.activityDataType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放係數種類",
    key: "emitParaType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={emitParaTypeOptions}
        name="emitParaType"
        label={<NameTranslate name="CalculateForm.emitParaType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "校正儀器種類",
    key: "InstrumentCalibrationSet",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={instrumentCalibrationSetOptions}
        name="InstrumentCalibrationSet"
        label={<NameTranslate name="Process.InstrumentCalibrationSet" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
];

const KITCHENS_ADD_FORM = ({
  parameterIDOptions = [],
  activityDataTypeOptions = [],
  emitParaTypeOptions = [],
  instrumentCalibrationSetOptions = [],
  ProccessDescriptionOptions = [],
  areaOptionList,
  param,
  form,
  setCodeModalType,
}) => [
  {
    label: "廠區 / 製程別",
    key: "ProcessName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ProcessName"
        label={<NameTranslate name="Kitchens.ProcessName" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "製程",
    key: "ProcessInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="ProcessInfo.Process" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("processList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("ProcessInfo.ProcessTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="ProcessInfo.SerialNumber" />}
            tooltip={t("ProcessInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessCode"]}
            label={<NameTranslate name="ProcessInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            disabled
            tooltip={t("ProcessInfo.Instructions2")}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessName"]}
            label={<NameTranslate name="ProcessInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "設備",
    key: "EquipmentInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="EquipmentInfo.Equipment" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("equipmentList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("EquipmentInfo.EquipmentTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="EquipmentInfo.SerialNumber" />}
            tooltip={t("EquipmentInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "ProductModel"]}
            label={<NameTranslate name="EquipmentInfo.ModelNumber" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentCode"]}
            label={<NameTranslate name="EquipmentInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip="屬固定源許可範圍之設備，設備代碼及其所對應名稱應與固定源許可一致，其他設備使用者可依據自廠實際情況填寫，設備代碼為4位數字，如為0007 循環流體床鍋爐，設備代碼及其所對應名稱請參閱[設備表]。"
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentName"]}
            label={<NameTranslate name="EquipmentInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "原燃物料或產品",
    key: "CombustiblesList",
    Node: (
      <ProCard
        bordered
        title={
          <NameTranslate name="CombustiblesList.RawFuelMaterialsProducts" />
        }
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("combustiblesList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CombustiblesList.RawFuelTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name="CombustibleCode"
            label={<NameTranslate name="CombustiblesList.CombustibleCode" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("CombustiblesList.Instructions")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["CombustiblesList", "DisplayNameTW"]}
            label={<NameTranslate name="CombustiblesList.DisplayName" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬生質能源",
    key: "IsBiomassEnergy",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsBiomassEnergy"
        label={<NameTranslate name="Kitchens.IsBiomassEnergy" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬汽電共生設備",
    key: "IsCogenerationEquipment",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsCogenerationEquipment"
        label={<NameTranslate name="Kitchens.IsCogenerationEquipment" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "製程類別",
    key: "ProccessDescription",
    Node:
      param === "Process" ? (
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={ProccessDescriptionOptions}
          name="ProccessDescription"
          label={<NameTranslate name="Kitchens.ProccessDescription" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      ) : null, // 当param != 'Process'时，Node为null
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動數據",
    key: "OriScalar",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Kitchens.OriScalar" />}
        name="OriScalar"
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
        fieldProps={{ precision: 4 }}
        min={0}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動數據分配比率%",
    key: "DistributionRatioNumber",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Kitchens.DistributionRatioNumber" />}
        name="DistributionRatioNumber"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
        initialValue={100}
        addonAfter="%"
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "負責單位",
    key: "ResponsibleUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ResponsibleUnit"
        label={<NameTranslate name="Kitchens.ResponsibleUnit" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備名稱",
    key: "Name",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Name"
        label={<NameTranslate name="Kitchens.Name" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "設備類別",
    key: "type",
    Node: (
      <SystemMenusDropDown
        key={uuidv4()}
        form={form}
        SystemTypeID={
          param === "Process"
            ? SystemTypes.IndustrialProcesses
            : SystemTypes.StationaryCombustion
        }
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "地區",
    key: "Area",
    Node: (
      <Form.Item
        key={uuidv4()}
        label={<NameTranslate name="Kitchens.Area" />}
        name="Area"
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      >
        <Cascader
          showSearch={{
            filter: (inputValue, path) =>
              path.some(
                (option) =>
                  option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                  -1
              ),
          }}
          options={parameterIDOptions}
        />
      </Form.Item>
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "係數單位",
    key: "Unit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Unit"
        label={<NameTranslate name="Kitchens.Unit" />}
        disabled
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "使用量",
    key: "Scalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={
          <span className="d-flex flex-column">
            <NameTranslate name="Kitchens.Scalar" />
            <small className="text-info">
              <NameTranslate name="Kitchens.ScalarNote" />
            </small>
          </span>
        }
        name="Scalar"
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          { required: true, message: t("FormMsg.Required") },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ActivityIntensityUnit"
        label={<NameTranslate name="Kitchens.ActivityIntensityUnit" />}
        rules={[
          {
            warningOnly: true,
            message: t("FormMsg.InconsistentUnit"),
            validator: (_, value) => {
              const Unit = form?.getFieldValue("Area")[1].split("/")[1];
              return changeItemBorderColor(value, {
                ActivityIntensityUnit: value,
                Unit,
              });
            },
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "原始熱值",
    key: "OriHeatValue",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Kitchens.OriHeatValue" />}
        name="OriHeatValue"
        disabled={true}
        fieldProps={{
          precision: 10,
          placeholder: t("Kitchens.HeatText"),
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "低位熱值加權平均",
    key: "heatValue",
    Node: (
      <ProFormDependency key={uuidv4()} name={["Area"]}>
        {({ Area }) => {
          let value = "";
          if (Area) {
            value = findAreaAndYear(
              areaOptionList,
              Area[Area.length - 1]
            ).OriHeatValue;
          }
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              colProps={{ md: 12 }}
              label={<NameTranslate name="Kitchens.heatValue" />}
              name="heatValue"
              disabled={!value}
              fieldProps={{
                precision: 10,
                placeholder: t("Kitchens.HeatText"),
              }}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "燃料熱值單位",
    key: "HeatValueUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="HeatValueUnit"
        label={<NameTranslate name="CalculateForm.FuelCalorificValueUnit" />}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="Kitchens.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備及照片",
    key: "referencePhoto",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="Kitchens.ReferenceServicePhotoUrl" />}
        name="referencePhoto"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referencePhoto", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referencePhoto", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "使用量佐證說明",
    key: "Evidence",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 6 }}
        name="Evidence"
        label={<NameTranslate name="Kitchens.Evidence" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "備註",
    key: "Description",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Description"
        label={<NameTranslate name="Kitchens.Description" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "活動數據種類",
    key: "activityDataType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={activityDataTypeOptions}
        name="activityDataType"
        label={<NameTranslate name="Kitchens.activityDataType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放係數種類",
    key: "emitParaType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={emitParaTypeOptions}
        name="emitParaType"
        label={<NameTranslate name="Kitchens.emitParaType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "校正儀器種類",
    key: "InstrumentCalibrationSet",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={instrumentCalibrationSetOptions}
        name="InstrumentCalibrationSet"
        label={<NameTranslate name="Kitchens.InstrumentCalibrationSet" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
];

const FIREEQUIPMENTS_ADD_FORM = ({
  parameterIDOptions = [],
  activityDataTypeOptions = [],
  emitParaTypeOptions = [],
  instrumentCalibrationSetOptions = [],
  EmissionDescriptionOptions = [],
  ActivityUnitOptions = [],
  param,
  form,
  setCodeModalType,
}) => [
  {
    label: "廠區 / 製程別",
    key: "ProcessName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ProcessName"
        label={<NameTranslate name="FireEquipment.ProcessName" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "製程",
    key: "ProcessInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="ProcessInfo.Process" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("processList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("ProcessInfo.ProcessTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="ProcessInfo.SerialNumber" />}
            tooltip={t("ProcessInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessCode"]}
            label={<NameTranslate name="ProcessInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("ProcessInfo.Instructions2")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessName"]}
            label={<NameTranslate name="ProcessInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "設備",
    key: "EquipmentInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="EquipmentInfo.Equipment" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("equipmentList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("EquipmentInfo.EquipmentTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="EquipmentInfo.SerialNumber" />}
            tooltip={t("EquipmentInfo.Instructions1")}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "ProductModel"]}
            label={<NameTranslate name="EquipmentInfo.ModelNumber" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentCode"]}
            label={<NameTranslate name="EquipmentInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("EquipmentInfo.Instructions2")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentName"]}
            label={<NameTranslate name="EquipmentInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "原燃物料或產品",
    key: "CombustiblesList",
    Node: (
      <ProCard
        bordered
        title={
          <NameTranslate name="CombustiblesList.RawFuelMaterialsProducts" />
        }
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("combustiblesList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CombustiblesList.RawFuelTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name="CombustibleCode"
            label={<NameTranslate name="CombustiblesList.CombustibleCode" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            tooltip={t("CombustiblesList.Instructions")}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["CombustiblesList", "DisplayNameTW"]}
            label={<NameTranslate name="CombustiblesList.DisplayName" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬生質能源",
    key: "IsBiomassEnergy",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsBiomassEnergy"
        label={<NameTranslate name="Process.IsBiomassEnergy" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "是否屬汽電共生設備",
    key: "IsCogenerationEquipment",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsCogenerationEquipment"
        label={<NameTranslate name="Process.IsCogenerationEquipment" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "逸散類別",
    key: "EmissionDescription",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={EmissionDescriptionOptions}
        name="EmissionDescription"
        label={<NameTranslate name="Process.EmissionCategory" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "負責單位",
    key: "ResponsibleUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ResponsibleUnit"
        label={<NameTranslate name="FireEquipment.ResponsibleUnit" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備名稱",
    key: "Name",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Name"
        label={<NameTranslate name="FireEquipment.Name" />}
        rules={[{ required: true, message: t("FormMsg.RequiredEquipment") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "數量",
    key: "Quantity",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="FireEquipment.Quantity" />}
        name="Quantity"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "原始填充量",
    key: "OriginalFill",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="FireEquipment.OriginalFill" />}
        name="OriginalFill"
        fieldProps={{ precision: 4 }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源",
    key: "ParameterID",
    Node: (
      <ProFormSelect
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={parameterIDOptions}
        showSearch
        name="ParameterID"
        label={<NameTranslate name="FireEquipment.ParameterID" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用量",
    key: "OriScalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="FireEquipment.Scalar" />}
        name="OriScalar"
        fieldProps={{ precision: 4 }}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "使用量",
    key: "Scalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="FireEquipment.Scalar" />}
        name="Scalar"
        fieldProps={{ precision: 4 }}
        rules={[
          // {
          //   validator: (_, value) => {
          //     if (value >= 0) {
          //       return Promise.resolve();
          //     }
          //     return Promise.reject(new Error(t("FormMsg.moreThan0")));
          //   },
          //   message: t("FormMsg.moreThan0"),
          // },
          { required: true, message: t("FormMsg.Required") },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動數據分配比率%",
    key: "DistributionRatioNumber",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="Process.DistributionRatioNumber" />}
        name="DistributionRatioNumber"
        rules={[{ required: true, message: t("FormMsg.Required") }]}
        initialValue={100}
        addonAfter="%"
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={ActivityUnitOptions}
        name="ActivityIntensityUnit"
        label={<NameTranslate name="Refrigerant.ActivityIntensityUnit" />}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="FireEquipment.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備照片",
    key: "referencePhoto",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="FireEquipment.ReferenceServicePhotoUrl" />}
        name="referencePhoto"
        title={<NameTranslate name="Buttons.Upload" />}
        // action={UPLOAD_REFERENCE_API}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referencePhoto", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referencePhoto", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "備註",
    key: "Description",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Description"
        label={<NameTranslate name="CalculateForm.Description" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "活動數據種類",
    key: "activityDataType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={activityDataTypeOptions}
        name="activityDataType"
        label={<NameTranslate name="FireEquipment.activityDataType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放係數種類",
    key: "emitParaType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={emitParaTypeOptions}
        name="emitParaType"
        label={<NameTranslate name="FireEquipment.emitParaType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "校正儀器種類",
    key: "InstrumentCalibrationSet",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={instrumentCalibrationSetOptions}
        name="InstrumentCalibrationSet"
        label={<NameTranslate name="Process.InstrumentCalibrationSet" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
];

const OTHERCOMPOUNDS_ADD_FORM = ({
  parameterIDOptions = [],
  activityDataTypeOptions = [],
  emitParaTypeOptions = [],
  instrumentCalibrationSetOptions = [],
  EmissionDescriptionOptions = [],
  form,
  warmGasTypeOptionList = [],
  setCodeModalType,
  otherCompoundsVisibleField,
  setOtherCompoundsVisibleField,
}) => [
  {
    label: "廠區 / 製程別",
    key: "ProcessName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ProcessName"
        label={<NameTranslate name="OtherCompounds.ProcessName" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "製程",
    key: "ProcessInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="ProcessInfo.Process" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("processList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("ProcessInfo.ProcessTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="ProcessInfo.SerialNumber" />}
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessCode"]}
            label={<NameTranslate name="ProcessInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["ProcessInformation", "ProcessName"]}
            label={<NameTranslate name="ProcessInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "設備",
    key: "EquipmentInformation",
    Node: (
      <ProCard
        bordered
        title={<NameTranslate name="EquipmentInfo.Equipment" />}
        extra={
          <Button
            type="link"
            onClick={() => {
              setCodeModalType("equipmentList");
            }}
            icon={<FileSearchOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("EquipmentInfo.EquipmentTable")}
          </Button>
        }
        className="shadow-sm mb-3"
      >
        <Space.Compact className="w-100">
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "SerialNumber"]}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            label={<NameTranslate name="EquipmentInfo.SerialNumber" />}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "ProductModel"]}
            label={<NameTranslate name="EquipmentInfo.ModelNumber" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentCode"]}
            label={<NameTranslate name="EquipmentInfo.Code" />}
            rules={[{ required: true, message: t("FormMsg.Required") }]}
            disabled
          />
          <ProFormText
            key={uuidv4()}
            name={["EquipmentInformation", "EquipmentName"]}
            label={<NameTranslate name="EquipmentInfo.Name" />}
            disabled
          />
        </Space.Compact>
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "原燃物料或產品",
    key: "CombustiblesList",
    Node: (
      <ProCard
        bordered
        title={
          <NameTranslate name="CombustiblesList.RawFuelMaterialsProducts" />
        }
        extra={
          <Space align="baseline">
            <Button
              type="link"
              onClick={() => {
                setCodeModalType("combustiblesList");
                setOtherCompoundsVisibleField("combustible");
              }}
              icon={<FileSearchOutlined />}
              className="d-flex align-items-center mt-2"
            >
              {t("CombustiblesList.RawFuelTable")}
            </Button>
            {/* <span>或</span>
              <Button
                type="link"
                onClick={() => setOtherCompoundsVisibleField('ingredient')}
                icon={<EditOutlined />}
                className="d-flex align-items-center mt-2"
              >
                自行填寫
              </Button> */}
          </Space>
        }
        className="shadow-sm mb-3"
      >
        {otherCompoundsVisibleField === "combustible" && (
          <Space.Compact className="w-100">
            <ProFormText
              key={uuidv4()}
              name="CombustibleCode"
              label={<NameTranslate name="CombustiblesList.CombustibleCode" />}
              rules={[{ required: true, message: t("FormMsg.Required") }]}
              disabled
            />
            <ProFormText
              key={uuidv4()}
              name={["CombustiblesList", "DisplayNameTW"]}
              label={<NameTranslate name="CombustiblesList.DisplayName" />}
              disabled
            />
          </Space.Compact>
        )}
        {otherCompoundsVisibleField === "ingredient" && (
          <ProFormText
            key={uuidv4()}
            colProps={{ md: 12 }}
            name="ingredientName"
            label={<NameTranslate name="OtherCompounds.ingredientName" />}
            fieldProps={{
              defaultValue: null,
            }}
            validateTrigger="onChange"
            rules={[
              {
                required: true,
                message: <NameTranslate name="FormMsg.Required" />,
              },
            ]}
          />
        )}
      </ProCard>
    ),
    isIII: true,
    isNormal: false,
  },
  // {
  //   label: "是否屬生質能源",
  //   key: "IsBiomassEnergy",
  //   Node: (
  //     <ProFormSelect
  //       allowClear={false}
  //       key={uuidv4()}
  //       colProps={{ md: 12 }}
  //       options={[
  //         { label: t("User.IsCompanyAdminNo"), value: false },
  //         { label: t("User.IsCompanyAdminYes"), value: true },
  //       ]}
  //       name="IsBiomassEnergy"
  //       label={<NameTranslate name="Process.IsBiomassEnergy" />}
  //       rules={[{ required: true, message: t("FormMsg.Required") }]}
  //     />
  //   ),
  //   isIII: true,
  //   isNormal: false,
  // },
  {
    label: "是否屬汽電共生設備",
    key: "IsCogenerationEquipment",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={[
          { label: t("User.IsCompanyAdminNo"), value: false },
          { label: t("User.IsCompanyAdminYes"), value: true },
        ]}
        name="IsCogenerationEquipment"
        label={<NameTranslate name="Process.IsCogenerationEquipment" />}
        rules={[{ required: true, message: t("FormMsg.Required") }]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  // {
  //   label: "製程/逸散/外購電力類別",
  //   key: "ProccessDescription",
  //   Node: (
  //     <ProFormSelect
  //       allowClear={false}
  //       key={uuidv4()}
  //       colProps={{ md: 12 }}
  //       options={EmissionDescriptionOptions}
  //       name="EmissionDescription"
  //       label="製程/逸散/外購電力類別"
  //       rules={[
  //         {
  //           required: true,
  //           message: <NameTranslate name="FormMsg.Required" />,
  //         },
  //       ]}
  //     />
  //   ),
  //   isIII: true,
  //   isNormal: false,
  // },
  {
    label: "負責單位",
    key: "ResponsibleUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="ResponsibleUnit"
        label={<NameTranslate name="OtherCompounds.ResponsibleUnit" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "設備名稱",
    key: "Name",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Name"
        label={<NameTranslate name="OtherCompounds.Name" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "用途",
    key: "warmGasType",
    Node: (
      <ProFormSelect
        allowClear={false}
        showSearch
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={warmGasTypeOptionList}
        name="warmGasType"
        label={<NameTranslate name="OtherCompounds.warmGasType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "數量",
    key: "Quantity",
    Node: (
      <ProFormDigit
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Quantity"
        label={<NameTranslate name="OtherCompounds.Quantity" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放源名稱",
    key: "ARnGWPid",
    Node: (
      <ProFormDependency key={uuidv4()} name={["SourceName"]}>
        {({ SourceName }) => {
          return (
            <ProFormSelect
              key={uuidv4()}
              colProps={{ md: 12 }}
              options={parameterIDOptions}
              name="ARnGWPid"
              label={<NameTranslate name="OtherCompounds.ParameterID" />}
              showSearch
              allowClear
              validateTrigger="onChange"
              onChange={(ARnGWPid) => {
                const ParameterIDLabel = parameterIDOptions.find(
                  ({ value }) => value === ARnGWPid
                )?.label;
                form?.setFieldValue("ingredientName", ParameterIDLabel);
              }}
              rules={[
                {
                  warningOnly: true,
                  message: t("FormMsg.InconsistentSource"),
                  validator: (_, value) => {
                    if (SourceName && value && SourceName !== value) {
                      return Promise.reject(
                        new Error(t("FormMsg.InconsistentSource"))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源名稱 (自行輸入)",
    key: "ingredientName",
    Node: (
      <ProFormDependency key={uuidv4()} name={["ARnGWPid"]}>
        {({ ARnGWPid: ParameterID }) => {
          const ParameterIDLabel = parameterIDOptions.find(
            ({ value }) => value === ParameterID
          )?.label;

          return (
            <ProFormText
              key={uuidv4()}
              colProps={{ md: 12 }}
              name="ingredientName"
              label={<NameTranslate name="OtherCompounds.ingredientName" />}
              fieldProps={{
                defaultValue: null,
              }}
              validateTrigger="onChange"
              rules={[
                {
                  required: true,
                  message: <NameTranslate name="FormMsg.Required" />,
                },
                {
                  warningOnly: true,
                  message: t("FormMsg.InconsistentSource"),
                  validator: (_, value) => {
                    if (
                      value &&
                      ParameterIDLabel &&
                      ParameterIDLabel !== value
                    ) {
                      return Promise.reject(
                        new Error(t("FormMsg.InconsistentSource"))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度",
    key: "OriScalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="OtherCompounds.Scalar" />}
        name="Scalar"
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
  {
    label: "活動強度",
    key: "Scalar",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="OtherCompounds.Scalar" />}
        name="Scalar"
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["Unit"]}>
        {({ Unit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                className="mb-0"
                name="ActivityIntensityUnit"
                label={
                  <NameTranslate name="OtherCompounds.ActivityIntensityUnit" />
                }
                rules={[
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit: value,
                        Unit,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "係數",
    key: "CO2Factor",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 6 }}
        fieldProps={{ precision: 10 }}
        label={<NameTranslate name="OtherCompounds.KgCO2e" />}
        name="CO2Factor"
        min={0}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "宣告單位",
    key: "Unit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["ActivityIntensityUnit"]}>
        {({ ActivityIntensityUnit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                name="Unit"
                label={<NameTranslate name="OtherCompounds.Unit" />}
                rules={[
                  {
                    required: true,
                    message: <NameTranslate name="FormMsg.Required" />,
                  },
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit,
                        Unit: value,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "GWP值",
    key: "GWPFactor",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 6 }}
        label={<NameTranslate name="OtherCompounds.GWPFactor" />}
        name="GWPFactor"
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="OtherCompounds.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "備註",
    key: "Description",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Description"
        label={<NameTranslate name="OtherCompounds.Description" />}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "活動數據種類",
    key: "activityDataType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={activityDataTypeOptions}
        name="activityDataType"
        label={<NameTranslate name="OtherCompounds.activityDataType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "排放係數種類",
    key: "emitParaType",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={emitParaTypeOptions}
        name="emitParaType"
        label={<NameTranslate name="OtherCompounds.emitParaType" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: true,
  },
  {
    label: "校正儀器種類",
    key: "InstrumentCalibrationSet",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={instrumentCalibrationSetOptions}
        name="InstrumentCalibrationSet"
        label={<NameTranslate name="Process.InstrumentCalibrationSet" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: true,
    isNormal: false,
  },
];

const LIFECYCLE_ADD_FORM = ({
  setParameterModalOpen,
  getUncertaintyAnalysis,
  form,
  activityDataTypeOptions,
  emitParaTypeOptions,
  coefficientData,
  setCoefficientData,
  setSelectedRowKeys,
}) => [
  {
    label: "評估項目",
    key: "GHGEvaluateItem",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="GHGEvaluateItem"
        label={<NameTranslate name="LifeCycle.RiskAssmtMaterialID" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "料號",
    key: "MaterialNo",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="MaterialNo"
        label={<NameTranslate name="LifeCycle.MaterialNo" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)",
    key: "ActivityIntensity",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="LifeCycle.Scalar" />}
        name="ActivityIntensity"
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
        fieldProps={{ precision: 4 }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["Unit"]}>
        {({ Unit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                className="mb-0"
                name="ActivityIntensityUnit"
                label={<NameTranslate name="LifeCycle.ActivityIntensityUnit" />}
                rules={[
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit: value,
                        Unit,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "係數 (kgCO2e)",
    key: "KgCO2e",
    Node: (
      <div className="d-flex align-items-center" key={uuidv4()}>
        <ProFormDigit
          placeholder={t("CalculateForm.NumberType")}
          key={uuidv4()}
          label={<NameTranslate name="LifeCycle.KgCO2e" />}
          name="KgCO2e"
          fieldProps={{ precision: 10 }}
          style={{ width: "90%" }}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <Button
          type="link"
          onClick={() => setParameterModalOpen(true)}
          icon={<FileSearchOutlined />}
          className="d-flex align-items-center mt-2"
        >
          {t("CalculateForm.ParameterList")}
        </Button>
        {coefficientData.Encoding && (
          <Button
            type="link"
            danger
            onClick={() => {
              form.resetFields(["KgCO2e"]);
              form.resetFields(["Instruction"]);
              setCoefficientData({});
              setSelectedRowKeys([]);
            }}
            icon={<DeleteOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CalculateForm.ClearTheFactor")}
          </Button>
        )}
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "",
    key: "KgCO2eTable",
    Node: coefficientData.Encoding && (
      <table className="table table-bordered border-end border-start">
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientLibrary")}
          </td>
          <td className="p-2">{coefficientData.Version}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientCategory")}
          </td>
          <td className="p-2">{coefficientData.CategoryDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientSubcategory")}
          </td>
          <td className="p-2">{coefficientData.TypeDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientNumber")}
          </td>
          <td className="p-2">{coefficientData.Encoding}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameEN}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientDisplayName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameTW}</td>
        </tr>
      </table>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數說明",
    key: "Instruction",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Instruction"
        label={<NameTranslate name="LifeCycle.ParameterDescription" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "宣告單位",
    key: "Unit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["ActivityIntensityUnit"]}>
        {({ ActivityIntensityUnit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                name="Unit"
                label={<NameTranslate name="LifeCycle.Unit" />}
                rules={[
                  {
                    required: true,
                    message: <NameTranslate name="FormMsg.Required" />,
                  },
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit,
                        Unit: value,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "碳排放量 (KgCO2e)",
    key: "EmissionKg",
    Node: (
      <ProFormDependency key={uuidv4()} name={["KgCO2e", "ActivityIntensity"]}>
        {({ KgCO2e = 0, ActivityIntensity = 0 }) => {
          const value = formatKgCO2e(KgCO2e * ActivityIntensity);
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="LifeCycle.EmissionKg" />}
              // name="EmissionT"
              value={value}
              min={0}
              fieldProps={{ precision: 4 }}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "情境說明",
    key: "Scenario",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="Scenario"
        label={<NameTranslate name="LifeCycle.Scenario" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="LifeCycle.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源數據品質等級評分",
    key: "quality",
    Node: (
      <div className="my-4">
        <h6 className="fw-bold">{t("AddForm.Emission_source")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={activityDataTypeOptions}
          name="activityDataType"
          label={<NameTranslate name="OtherCompounds.activityDataType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={emitParaTypeOptions}
          name="emitParaType"
          label={<NameTranslate name="OtherCompounds.emitParaType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數來源",
    key: "DataSource",
    Node: (
      <div className="mt-5">
        <h6 className="fw-bold">{t("AddForm.Uncertainty_assessment")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataSource1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataSource2"),
              value: "2",
            },
          ]}
          name="DataSource"
          label={<NameTranslate name="LifeCycle.DataSource" />}
          onChange={getUncertaintyAnalysis}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataAttribute1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataAttribute2"),
              value: "2",
            },
          ]}
          name="DataAttribute"
          label={<NameTranslate name="LifeCycle.DataAttribute" />}
          onChange={getUncertaintyAnalysis}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "不確定性分析",
    key: "UncertaintyAnalysis",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="UncertaintyAnalysis"
        label={<NameTranslate name="LifeCycle.Uncertainty" />}
        disabled
      />
    ),
    isIII: false,
    isNormal: true,
  },
];

const LIFECYCLEFORPEOPLE_ADD_FORM = ({
  setParameterModalOpen,
  getUncertaintyAnalysis,
  PercentageForLifeStage,
  form,
  activityDataTypeOptions,
  emitParaTypeOptions,
  sourceDevice,
}) => [
  {
    label: "評估項目",
    key: "GHGEvaluateItem",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="GHGEvaluateItem"
        label={<NameTranslate name="LifeCycle.RiskAssmtMaterialID" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
  },
  {
    label: "活動強度(使用量)",
    key: "ActivityIntensity",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="LifeCycle.Scalar" />}
        name="ActivityIntensity"
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
        fieldProps={{ precision: 4 }}
        min={0}
      />
    ),
  },
  {
    label: "活動強度(使用量)單位",
    key: "ActivityIntensityUnit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["Unit"]}>
        {({ Unit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                className="mb-0"
                name="ActivityIntensityUnit"
                label={t("LifeCycle.ActivityIntensityUnit")}
                rules={[
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit: value,
                        Unit,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
  },
  {
    // 選係數表
    label: "係數 (kgCO2e)",
    key: "KgCO2e",
    Node: (
      <div className="d-flex align-items-center" key={uuidv4()}>
        <ProFormDigit
          placeholder={t("CalculateForm.NumberType")}
          key={uuidv4()}
          label={<NameTranslate name="LifeCycle.KgCO2e" />}
          name="KgCO2e"
          fieldProps={{ precision: 10 }}
          style={{ width: "90%" }}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <Button
          type="link"
          onClick={() => setParameterModalOpen(true)}
          icon={<FileSearchOutlined />}
          className="d-flex align-items-center mt-2"
        >
          {t("CalculateForm.ParameterList")}
        </Button>
      </div>
    ),
  },
  {
    // 選係數表
    label: "",
    key: "KgCO2eTable",
    Node: sourceDevice.Encoding && (
      <table className="table table-bordered border-end border-start">
        <tr>
          <td className="bg-light border-end p-2">係數庫</td>
          <td className="p-2">{sourceDevice.Version}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">係數類別</td>
          <td className="p-2">{sourceDevice.CategoryDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">係數係數子類別</td>
          <td className="p-2">{sourceDevice.TypeDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">係數編號</td>
          <td className="p-2">{sourceDevice.Encoding}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">名稱</td>
          <td className="p-2">{sourceDevice.DisplayNameEN}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">顯示名稱</td>
          <td className="p-2">{sourceDevice.DisplayNameTW}</td>
        </tr>
      </table>
    ),
  },
  {
    label: "係數說明",
    key: "Instruction",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="Instruction"
        label={<NameTranslate name="LifeCycle.ParameterDescription" />}
      />
    ),
  },
  {
    label: "宣告單位",
    key: "Unit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["ActivityIntensityUnit"]}>
        {({ ActivityIntensityUnit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                name="Unit"
                label={<NameTranslate name="LifeCycle.Unit" />}
                rules={[
                  {
                    required: true,
                    message: <NameTranslate name="FormMsg.Required" />,
                  },
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit,
                        Unit: value,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
  },
  {
    label: "碳排放量 (噸)",
    key: "EmissionT",
    Node: (
      <ProFormDependency key={uuidv4()} name={["KgCO2e", "ActivityIntensity"]}>
        {({ KgCO2e = 0, ActivityIntensity = 0 }) => {
          const value = formatKgCO2e((KgCO2e * ActivityIntensity) / 1000);
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="LifeCycle.EmissionT" />}
              name="EmissionT"
              value={value}
              min={0}
              fieldProps={{ precision: 4 }}
            />
          );
        }}
      </ProFormDependency>
    ),
  },
  {
    label: "情境說明",
    key: "Scenario",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="Scenario"
        label={<NameTranslate name="LifeCycle.Scenario" />}
      />
    ),
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="LifeCycle.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
  },
  {
    label: "排放源數據品質等級評分",
    key: "quality",
    Node: (
      <div className="my-4">
        <h6 className="fw-bold">{t("AddForm.Emission_source")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={activityDataTypeOptions}
          name="activityDataType"
          label={<NameTranslate name="OtherCompounds.activityDataType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={emitParaTypeOptions}
          name="emitParaType"
          label={<NameTranslate name="OtherCompounds.emitParaType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      </div>
    ),
  },
  {
    label: "係數來源",
    key: "DataSource",
    Node: (
      <div className="mt-5">
        <h6 className="fw-bold">{t("AddForm.Uncertainty_assessment")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataSource1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataSource2"),
              value: "2",
            },
          ]}
          name="DataSource"
          label={<NameTranslate name="LifeCycle.DataSource" />}
          onChange={getUncertaintyAnalysis}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataAttribute1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataAttribute2"),
              value: "2",
            },
          ]}
          name="DataAttribute"
          label={<NameTranslate name="LifeCycle.DataAttribute" />}
          onChange={getUncertaintyAnalysis}
        />
      </div>
    ),
  },
  {
    label: "不確定性分析",
    key: "UncertaintyAnalysis",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="UncertaintyAnalysis"
        label={<NameTranslate name="LifeCycle.Uncertainty" />}
        disabled
      />
    ),
  },
];

const COMMUTING_ADD_FORM = ({
  setParameterModalOpen,
  parameterIDOptions = [],
  getUncertaintyAnalysis,
  activityDataTypeOptions,
  emitParaTypeOptions,
  param,
  form,
  coefficientData,
  setCoefficientData,
  setSelectedRowKeys,
}) => [
  {
    label: "交通方式",
    key: "Commuting",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={parameterIDOptions}
        name="Commuting"
        label={<NameTranslate name="Commuting.Transportation" />}
        fieldProps={{
          placement: "bottom",
        }}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用此方式之員工或部門名稱",
    key: "StaffOrDepartment",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="StaffOrDepartment"
        label={<NameTranslate name="Commuting.EmployeeOrDepartment" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用此方式之員工總數",
    key: "TotalEmployees",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="TotalEmployees"
        label={<NameTranslate name="Commuting.TotalEmployees" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "盤查期間工作天數",
    key: "WorkingDays",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="WorkingDays"
        label={
          param == "Commuting" ? (
            <NameTranslate name="Commuting.WorkingDays" />
          ) : param == "Visitor" ? (
            <NameTranslate name="Commuting.VisitsDays" />
          ) : (
            <NameTranslate name="Commuting.TripsDays" />
          )
        }
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  // {
  //   label: "往返",
  //   key: "RoundTrip",
  //   Node: (
  //     <ProFormSelect
  //       allowClear={false}
  //       key={uuidv4()}
  //       colProps={{ md: 12 }}
  //       options={[
  //         {
  //           label: t("Commuting.Departure"),
  //           value: 1,
  //         },
  //         {
  //           label: t("Commuting.Return"),
  //           value: 2,
  //         },
  //         {
  //           label: t("Commuting.RoundTrip"),
  //           value: 3,
  //         },
  //       ]}
  //       name="RoundTrip"
  //       label={<NameTranslate name="Commuting.RoundTrip" />}
  //       rules={[
  //         {
  //           required: true,
  //           message: <NameTranslate name="FormMsg.Required" />,
  //         },
  //       ]}
  //     />
  //   ),
  // },
  {
    label: "移動距離 km",
    key: "AverageMovingDistance",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="AverageMovingDistance"
        label={<NameTranslate name="Commuting.AverageMovingDistance" />}
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "係數 (kgCO2e)",
    key: "Coefficient",
    Node: (
      <div className="d-flex align-items-center" key={uuidv4()}>
        <ProFormDigit
          placeholder={t("CalculateForm.NumberType")}
          key={uuidv4()}
          label={<NameTranslate name="CalculateForm.KgCO2e" />}
          name="Coefficient"
          fieldProps={{ precision: 10 }}
          style={{ width: "90%" }}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <Button
          type="link"
          onClick={() => setParameterModalOpen(true)}
          icon={<FileSearchOutlined />}
          className="d-flex align-items-center mt-2"
        >
          {t("CalculateForm.ParameterList")}
        </Button>
        {coefficientData.ParameterID && (
          <Button
            type="link"
            danger
            onClick={() => {
              form.resetFields(["Coefficient"]);
              form.resetFields(["CoefficientNote"]);
              setCoefficientData({});
              setSelectedRowKeys([]);
            }}
            icon={<DeleteOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CalculateForm.ClearTheFactor")}
          </Button>
        )}
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "",
    key: "KgCO2eTable",
    Node: coefficientData.ParameterID && (
      <table className="table table-bordered border-end border-start">
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientLibrary")}
          </td>
          <td className="p-2">{coefficientData.Version}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientCategory")}
          </td>
          <td className="p-2">{coefficientData.CategoryDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientSubcategory")}
          </td>
          <td className="p-2">{coefficientData.TypeDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientNumber")}
          </td>
          <td className="p-2">{coefficientData.Encoding}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameEN}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientDisplayName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameTW}</td>
        </tr>
      </table>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數說明",
    key: "CoefficientNote",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="CoefficientNote"
        label={<NameTranslate name="LifeCycle.ParameterDescription" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "單位",
    key: "CoefficientUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="CoefficienUnit"
        label={<NameTranslate name="CalculateForm.Unit" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "碳排放量",
    key: "KgCO2e",
    Node: (
      <ProFormDependency
        key={uuidv4()}
        name={[
          "Coefficient",
          "AverageMovingDistance",
          "WorkingDays",
          "TotalEmployees",
        ]}
      >
        {({
          Coefficient = 0,
          AverageMovingDistance = 0,
          WorkingDays = 0,
          TotalEmployees = 0,
        }) => {
          const value = formatScalar(
            Coefficient * AverageMovingDistance * WorkingDays * TotalEmployees
          );
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="LifeCycle.EmissionKg" />}
              // name="KgCO2e"
              value={value}
              min={0}
              fieldProps={{ precision: 4 }}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "情境說明",
    key: "MovingScenario",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="MovingScenario"
        label={<NameTranslate name="LifeCycle.Scenario" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="CalculateForm.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源數據品質等級評分",
    key: "quality",
    Node: (
      <div className="my-4">
        <h6 className="fw-bold">{t("AddForm.Emission_source")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={activityDataTypeOptions}
          name="activityDataType"
          label={<NameTranslate name="OtherCompounds.activityDataType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={emitParaTypeOptions}
          name="emitParaType"
          label={<NameTranslate name="OtherCompounds.emitParaType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數來源",
    key: "DataSource",
    Node: (
      <div className="mt-5">
        <h6 className="fw-bold">{t("AddForm.Uncertainty_assessment")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataSource1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataSource2"),
              value: "2",
            },
          ]}
          name="DataSource"
          label={<NameTranslate name="LifeCycle.DataSource" />}
          onChange={getUncertaintyAnalysis}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataAttribute1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataAttribute2"),
              value: "2",
            },
          ]}
          name="DataAttribute"
          label={<NameTranslate name="LifeCycle.DataAttribute" />}
          onChange={getUncertaintyAnalysis}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "不確定性分析",
    key: "UncertaintyAnalysis",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="UncertaintyAnalysis"
        label={<NameTranslate name="LifeCycle.Uncertainty" />}
        disabled
      />
    ),
    isIII: false,
    isNormal: true,
  },
];

const PURCHASEDPRODUCT_ADD_FORM = ({
  setParameterModalOpen,
  getUncertaintyAnalysis,
  PercentageForLifeStage = 0,
  form,
  activityDataTypeOptions,
  emitParaTypeOptions,
  coefficientData,
  setCoefficientData,
  setSelectedRowKeys,
}) => [
  {
    label: "料號",
    key: "MaterialNo",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="MaterialNo"
        label={<NameTranslate name="PurchasedGood.MaterialNo" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    title: "物料名稱",
    key: "MaterialName",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="MaterialName"
        label={<NameTranslate name="PurchasedGood.MaterialName" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "單一物料物理規格",
    key: "MaterialSpec",
    Node: (
      <ProFormDependency name={["Unit"]} key={uuidv4()}>
        {({ Unit }) => {
          return (
            <Form.Item
              key={uuidv4()}
              label={
                <span className="d-flex flex-column">
                  {<NameTranslate name="PurchasedGood.MaterialSpec" />}
                  <small className="text-info">
                    {<NameTranslate name="PurchasedGood.MaterialSpecNote" />}
                  </small>
                </span>
              }
              required
            >
              <Space.Compact className="w-100">
                <ProFormDigit
                  noStyle
                  name="MaterialSpec"
                  placeholder={t("CalculateForm.NumberType")}
                  rules={[
                    {
                      required: true,
                      message: <NameTranslate name="FormMsg.Required" />,
                    },
                  ]}
                />
                <Form.Item
                  noStyle
                  name="ActivityIntensityUnit"
                  label={
                    <NameTranslate name="PurchasedGood.ActivityIntensityUnit" />
                  }
                  className="mb-0"
                  rules={[
                    {
                      warningOnly: true,
                      message: t("FormMsg.InconsistentUnit"),
                      validator: (_, value) => {
                        return changeItemBorderColor(value, {
                          ActivityIntensityUnit: value,
                          Unit,
                        });
                      },
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "50%",
                    }}
                    placeholder={t("CalculateForm.ActivityUnit")}
                  />
                </Form.Item>
                <WarningText form={form} />
              </Space.Compact>
            </Form.Item>
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "年度採購數量",
    key: "AnnualPurchaseAmount",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        label={<NameTranslate name="PurchasedGood.AnnualPurchaseAmount" />}
        name="AnnualPurchaseAmount"
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "活動強度(使用量)",
    key: "ActivityIntensity",
    Node: (
      <ProFormDependency
        key={uuidv4()}
        name={["MaterialSpec", "AnnualPurchaseAmount"]}
      >
        {({ MaterialSpec = 1, AnnualPurchaseAmount = 0 }) => {
          const value = MaterialSpec * AnnualPurchaseAmount;
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="PurchasedGood.Scalar" />}
              name="ActivityIntensity"
              value={value}
              min={0}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "係數",
    key: "ParameterValue",
    Node: (
      <div className="d-flex align-items-center" key={uuidv4()}>
        <ProFormDigit
          placeholder={t("CalculateForm.NumberType")}
          label={<NameTranslate name="PurchasedGood.KgCO2e" />}
          name="ParameterValue"
          fieldProps={{ precision: 10 }}
          style={{ width: "90%" }}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <Button
          type="link"
          onClick={() => setParameterModalOpen(true)}
          icon={<FileSearchOutlined />}
          className="d-flex align-items-center mt-2"
        >
          {t("CalculateForm.ParameterList")}
        </Button>
        {coefficientData.ParameterID && (
          <Button
            type="link"
            danger
            onClick={() => {
              form.resetFields(["ParameterValue"]);
              form.resetFields(["ParameterDescription"]);
              setCoefficientData({});
              setSelectedRowKeys([]);
            }}
            icon={<DeleteOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CalculateForm.ClearTheFactor")}
          </Button>
        )}
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "",
    key: "KgCO2eTable",
    Node: coefficientData.ParameterID && (
      <table className="table table-bordered border-end border-start">
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientLibrary")}
          </td>
          <td className="p-2">{coefficientData.Version}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientCategory")}
          </td>
          <td className="p-2">{coefficientData.CategoryDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientSubcategory")}
          </td>
          <td className="p-2">{coefficientData.TypeDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientNumber")}
          </td>
          <td className="p-2">{coefficientData.Encoding}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameEN}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientDisplayName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameTW}</td>
        </tr>
      </table>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "功能/宣告單位",
    key: "Unit",
    Node: (
      <ProFormDependency key={uuidv4()} name={["ActivityIntensityUnit"]}>
        {({ ActivityIntensityUnit }) => {
          return (
            <>
              <ProFormText
                key={uuidv4()}
                colProps={{ md: 12 }}
                name="Unit"
                label={<NameTranslate name="PurchasedGood.Unit" />}
                rules={[
                  {
                    required: true,
                    message: <NameTranslate name="FormMsg.Required" />,
                  },
                  {
                    warningOnly: true,
                    message: t("FormMsg.InconsistentUnit"),
                    validator: (_, value) => {
                      return changeItemBorderColor(value, {
                        ActivityIntensityUnit,
                        Unit: value,
                      });
                    },
                  },
                ]}
              />
              <WarningText form={form} />
            </>
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "碳排放量",
    key: "KgCO2e",
    Node: (
      <ProFormDependency
        key={uuidv4()}
        name={["ParameterValue", "AnnualPurchaseAmount", "MaterialSpec"]}
      >
        {({
          ParameterValue = 0,
          AnnualPurchaseAmount = 0,
          MaterialSpec = 0,
        }) => {
          const value = formatScalar(
            ParameterValue * AnnualPurchaseAmount * MaterialSpec
          );
          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="PurchasedGood.EmissionKg" />}
              // name="KgCO2e"
              value={isNaN(+value) ? 0 : +value}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數說明",
    key: "ParameterDescription",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ParameterDescription"
        label={<NameTranslate name="LifeCycle.ParameterDescription" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="CalculateForm.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "備註",
    key: "Comment",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="Comment"
        label={<NameTranslate name="CalculateForm.Description" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源數據品質等級評分",
    key: "quality",
    Node: (
      <div className="my-4">
        <h6 className="fw-bold">{t("AddForm.Emission_source")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={activityDataTypeOptions}
          name="activityDataType"
          label={<NameTranslate name="OtherCompounds.activityDataType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={emitParaTypeOptions}
          name="emitParaType"
          label={<NameTranslate name="OtherCompounds.emitParaType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數來源",
    key: "DataSource",
    Node: (
      <div className="mt-5">
        <h6 className="fw-bold">{t("AddForm.Uncertainty_assessment")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataSource1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataSource2"),
              value: "2",
            },
          ]}
          name="DataSource"
          label={<NameTranslate name="LifeCycle.DataSource" />}
          onChange={getUncertaintyAnalysis}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataAttribute1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataAttribute2"),
              value: "2",
            },
          ]}
          name="DataAttribute"
          label={<NameTranslate name="LifeCycle.DataAttribute" />}
          onChange={getUncertaintyAnalysis}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "不確定性分析",
    key: "UncertaintyAnalysis",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="UncertaintyAnalysis"
        label={<NameTranslate name="LifeCycle.Uncertainty" />}
        disabled
      />
    ),
    isIII: false,
    isNormal: true,
  },
];

const UPSTREAMTRANSPORT_ADD_FORM = ({
  setParameterModalOpen,
  getUncertaintyAnalysis,
  activityDataTypeOptions,
  TransportTypeOptions,
  emitParaTypeOptions,
  form,
  coefficientData,
  setCoefficientData,
  setSelectedRowKeys,
}) => [
  {
    label: "評估項目",
    key: "CargoName",
    Node: (
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.RiskAssmtMaterialID" />}
        name="CargoName"
        colProps={{ md: 12 }}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "路程編號",
    key: "JourneyNO",
    Node: (
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.JourneyNO" />}
        name="JourneyNO"
        colProps={{ md: 12 }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "運輸方式",
    key: "TransportTypeID",
    Node: (
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={TransportTypeOptions}
        name="TransportTypeID"
        label={<NameTranslate name="UpstreamTransport.Method" />}
        rules={[
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "運輸起點",
    key: "StartLocation",
    Node: (
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.StartLocation" />}
        name="StartLocation"
        colProps={{ md: 12 }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "運輸終點",
    key: "EndLocation",
    Node: (
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.EndLocation" />}
        name="EndLocation"
        colProps={{ md: 12 }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "重量 (kg)",
    key: "TransportWeight",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="TransportWeight"
        label={<NameTranslate name="UpstreamTransport.TransportWeight" />}
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "運輸距離 (km)",
    key: "TransportDistance",
    Node: (
      <ProFormDigit
        placeholder={t("CalculateForm.NumberType")}
        key={uuidv4()}
        colProps={{ md: 8 }}
        name="TransportDistance"
        label={<NameTranslate name="UpstreamTransport.TransportDistance" />}
        fieldProps={{ precision: 4 }}
        rules={[
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("FormMsg.moreThan0")));
            },
            message: t("FormMsg.moreThan0"),
          },
          {
            required: true,
            message: <NameTranslate name="FormMsg.Required" />,
          },
        ]}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "Ton*Km",
    key: "Scalar",
    Node: (
      <ProFormDependency
        key={uuidv4()}
        name={["TransportWeight", "TransportDistance"]}
      >
        {({ TransportWeight = 0, TransportDistance = 0 }) => {
          const value = formatScalar(
            (TransportWeight * TransportDistance) / 1000
          );
          return (
            <ProFormDigit
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label="Ton*Km"
              value={value}
              min={0}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "係數 (kgCO2e)",
    key: "ParameterValue",
    Node: (
      <div className="d-flex align-items-center" key={uuidv4()}>
        <ProFormDigit
          placeholder={t("CalculateForm.NumberType")}
          label={<NameTranslate name="CalculateForm.KgCO2e" />}
          name="ParameterValue"
          style={{ width: "90%" }}
          fieldProps={{ precision: 10 }}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <Button
          type="link"
          onClick={() => setParameterModalOpen(true)}
          icon={<FileSearchOutlined />}
          className="d-flex align-items-center mt-2"
        >
          {t("CalculateForm.ParameterList")}
        </Button>
        {coefficientData.ParameterID && (
          <Button
            type="link"
            danger
            onClick={() => {
              form.resetFields(["ParameterValue"]);
              form.resetFields(["ScalarComment"]);
              setCoefficientData({});
              setSelectedRowKeys([]);
            }}
            icon={<DeleteOutlined />}
            className="d-flex align-items-center mt-2"
          >
            {t("CalculateForm.ClearTheFactor")}
          </Button>
        )}
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    // 選係數表
    label: "",
    key: "KgCO2eTable",
    Node: coefficientData.ParameterID && (
      <table className="table table-bordered border-end border-start">
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientLibrary")}
          </td>
          <td className="p-2">{coefficientData.Version}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientCategory")}
          </td>
          <td className="p-2">{coefficientData.CategoryDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientSubcategory")}
          </td>
          <td className="p-2">{coefficientData.TypeDisplayNameTW}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientNumber")}
          </td>
          <td className="p-2">{coefficientData.Encoding}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameEN}</td>
        </tr>
        <tr>
          <td className="bg-light border-end p-2">
            {t("CalculateForm.CoefficientDisplayName")}
          </td>
          <td className="p-2">{coefficientData.DisplayNameTW}</td>
        </tr>
      </table>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數說明",
    key: "ScalarComment",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ScalarComment"
        label={<NameTranslate name="LifeCycle.ParameterDescription" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "單位",
    key: "ScalarUnit",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="ScalarUnit"
        label={<NameTranslate name="CalculateForm.Unit" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "碳排放量(KgCO2e)",
    key: "KgCO2e",
    Node: (
      <ProFormDependency
        key={uuidv4()}
        name={["TransportDistance", "TransportWeight", "ParameterValue"]}
      >
        {({
          TransportDistance = 0,
          TransportWeight = 0,
          ParameterValue = 0,
        }) => {
          // 計算 value，並確保它是一個有效的數字
          const value =
            ((TransportDistance * TransportWeight) / 1000) * ParameterValue;
          let formattedValue = "0.0000"; // 預設格式化值

          if (typeof value === "number" && !isNaN(value)) {
            formattedValue = value.toFixed(4); // 格式化數字到小數點後4位
          }

          return (
            <ProFormDigit
              placeholder={t("CalculateForm.NumberType")}
              key={uuidv4()}
              disabled
              colProps={{ md: 12 }}
              label={<NameTranslate name="LifeCycle.EmissionKg" />}
              value={formattedValue}
              min={0}
              fieldProps={{ precision: 4 }}
            />
          );
        }}
      </ProFormDependency>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "情境說明",
    key: "MovingScenario",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        value=""
        name="MovingScenario"
        label={<NameTranslate name="LifeCycle.Scenario" />}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "使用量佐證文件",
    key: "referenceFile",
    Node: (
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        // extra="支援副檔名：.jpg、.png、.pdf"
        label={<NameTranslate name="CalculateForm.referenceFile" />}
        name="referenceFile"
        title={<NameTranslate name="Buttons.Upload" />}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              return;
            } else {
              form?.setFieldValue("referenceFile", [file]);
            }
          },
          onRemove: (file) => {
            form?.setFieldValue("referenceFile", []);
          },
          beforeUpload: () => {
            return false;
          },
        }}
      />
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "排放源數據品質等級評分",
    key: "quality",
    Node: (
      <div className="my-4">
        <h6 className="fw-bold">{t("AddForm.Emission_source")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={activityDataTypeOptions}
          name="activityDataType"
          label={<NameTranslate name="OtherCompounds.activityDataType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={emitParaTypeOptions}
          name="emitParaType"
          label={<NameTranslate name="OtherCompounds.emitParaType" />}
          rules={[
            {
              required: true,
              message: <NameTranslate name="FormMsg.Required" />,
            },
          ]}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "係數來源",
    key: "DataSource",
    Node: (
      <div className="mt-5">
        <h6 className="fw-bold">{t("AddForm.Uncertainty_assessment")}</h6>
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataSource1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataSource2"),
              value: "2",
            },
          ]}
          name="DataSource"
          label={<NameTranslate name="LifeCycle.DataSource" />}
          onChange={getUncertaintyAnalysis}
        />
        <ProFormSelect
          allowClear={false}
          key={uuidv4()}
          colProps={{ md: 12 }}
          options={[
            {
              label: t("LifeCycle.DataAttribute1"),
              value: "1",
            },
            {
              label: t("LifeCycle.DataAttribute2"),
              value: "2",
            },
          ]}
          name="DataAttribute"
          label={<NameTranslate name="LifeCycle.DataAttribute" />}
          onChange={getUncertaintyAnalysis}
        />
      </div>
    ),
    isIII: false,
    isNormal: true,
  },
  {
    label: "不確定性分析",
    key: "UncertaintyAnalysis",
    Node: (
      <ProFormText
        key={uuidv4()}
        colProps={{ md: 12 }}
        name="UncertaintyAnalysis"
        label={<NameTranslate name="LifeCycle.Uncertainty" />}
        disabled
      />
    ),
    isIII: false,
    isNormal: true,
  },
];

export {
  VEHICLES_ADD_FORM,
  REFRIGERANT_ADD_FORM,
  KITCHENS_ADD_FORM,
  FIREEQUIPMENTS_ADD_FORM,
  OTHERCOMPOUNDS_ADD_FORM,
  LIFECYCLE_ADD_FORM,
  LIFECYCLEFORPEOPLE_ADD_FORM,
  COMMUTING_ADD_FORM,
  PURCHASEDPRODUCT_ADD_FORM,
  UPSTREAMTRANSPORT_ADD_FORM,
};
