import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { notification } from "antd";

async function exportMultipleChartsToPdf(charts, cols, direction = "vertical") {
  const doc = direction === "horizontal" ? new jsPDF("l") : new jsPDF();
  const promises = [];

  let padding = 10;
  let x = padding;
  let y = padding;

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const elWidth = pageWidth - 2 * padding;

  const getImage = (chart, chartImage) => {
    // const elHeight = (pageHeight - 3 * padding) / cols; // 按比例分高度
    let elHeight =
      ((chart.clientHeight * (pageHeight - 3 * padding)) /
        charts.clientHeight) *
      0.85;

    elHeight =
      elHeight < pageHeight / cols
        ? (pageHeight - 3 * padding) / cols
        : elHeight;

    if (y + elHeight > pageHeight) {
      doc.addPage(); // 添加新頁，如果需要將每個圖表放在不同頁面
      y = padding; // 換頁後，高度還原
      x = padding;
    }

    // 添加圖像到 PDF，可以根據需要調整位置和大小
    doc.addImage(chartImage, "PNG", x, y, elWidth, elHeight);

    y += elHeight + padding;
    // if (promises.length % 2 !== 0) {
    //   y += elHeight + padding;
    // }

    x += elWidth + padding;

    if (x >= pageWidth) {
      x = padding;
    }

    if (y > pageHeight && promises.length !== charts.childNodes.length - 1) {
      doc.addPage(); // 添加新頁，如果需要將每個圖表放在不同頁面
      y = padding; // 換頁後，高度還原
      x = padding;
    }
  };


  for (const chart of charts.childNodes) {
    if (chart.childNodes?.[0].childNodes.length > 1) {
      for (const node of chart.childNodes?.[0].childNodes) {
        if(node.children?.[0].tagName === "SMALL"){
          continue;
        }
        const canvas = await html2canvas(node, {
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
        });
        const chartImage = canvas.toDataURL("image/png");

        getImage(chart, chartImage);

        promises.push(chartImage);
      }
    } else {
      const canvas = await html2canvas(chart, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
      });
      const chartImage = canvas.toDataURL("image/png");

      getImage(chart, chartImage);

      promises.push(chartImage);
    }
  }

  // 等待所有圖像處理完成
  await Promise.all(promises);

  // 保存 PDF
  doc.save("charts.pdf");

  notification.destroy("loading");
}

export { exportMultipleChartsToPdf };
