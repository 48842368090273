const breadcrumbList = {
  user: "user",
  account: "account",
  enterprise: "enterprise",
  project: "project",
  add: "add",
  boundary: "boundary",
  calculate: "calculate",
  analyse: "analyse",
  risk: "risk",
  compare: "compare",
  advisercompare: "compare",
  sbtisetting: "sbtisetting",
  ghgreport: "ghgreport",
  sbtichart: "sbtichart",
  sbtiequipment: "sbtiequipment",
  noticeboard: "noticeboard",
  "project-management": "project-management"
};

export default breadcrumbList;
