function findParameterID({ areaOptionList, Area, Year, FuelType = "" }) {
  return areaOptionList.find(({ Area: a, Year: y, FuelType: f }) =>
    FuelType
      ? f.includes(FuelType) && Area === a && Year === y
      : Area === a && Year === y
  );
}

const findAreaAndYear = (areaOptionList, para) => {
  return areaOptionList?.find(({ key }) => key === para) || null;
};

export { findParameterID, findAreaAndYear };
